import { IonSearchbar } from "@ionic/react";
import LeftArrow from "assets/images/icon/svg/LeftArrow";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function NavBar({ searchProduct, defalutTarm }) {
	let navigate = useNavigate();
	let [tarm, setTarm] = useState(defalutTarm);
	return (
		<div className={`search-header-wrapper flex items-center px-4 py-4`}>
			<div
				className='short-nav left-4 top-7 bg-white rounded-xl z-10 mr-3'
				style={{
					height: 40,
					width: 40,
					boxShadow: "0 5px 10px rgba(0,0,0,.1)",
				}}
				onClick={() => navigate(-1)}>
				<div className=' h-full flex items-center justify-center'>
					<LeftArrow />
				</div>
			</div>
			<div className='w-full'>
				<IonSearchbar
					className='font-sans text-12 product-search'
					placeholder='Search for your products'
					value={tarm}
					onIonChange={(e) => setTarm(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === "Enter") {
							searchProduct(tarm);
						}
					}}></IonSearchbar>
			</div>
		</div>
	);
}
