import React from "react";

// import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import { useSelector } from "react-redux";

export default function Registration() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='flex justify-center flex-col'>
			<div className='flex justify-center'>
				<p className='text-20 font-bold mb-[50px]'>
					{ekkLanguage.registration.employee}
				</p>
			</div>
			<div className='flex justify-center items-center mb-[50px]'>
				<img
					className='w-[108px] h-40 mr-[10px]'
					src='/assets/images/hub/boy.png'
					alt='boy'
				/>
				<img
					className='w-[108px] h-40'
					src='/assets/images/hub/girl.png'
					alt='girl'
				/>
			</div>
			<div className='flex justify-center px-5 text-justify mb-40'>
				<p className='text-primary font-semibold text-16'>
					{ekkLanguage.registration.warning}
				</p>
			</div>
			<div className='flex justify-center px-5 '>
				<button className='w-full bg-[#00B224] shadow-md font-bold text-20 text-white h-[50px] rounded'>
					<a
						target='_blank'
						rel='noreferrer'
						href='https://eh2t.azurewebsites.net/'
					>
						{ekkLanguage.registration.btnLabel}
					</a>
				</button>
			</div>
		</div>
	);
}
