import {
	IonButton,
	IonCol,
	IonGrid,
	IonIcon,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import { getUser } from "config/user";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "services/Auth";

export default function ChangePassword() {
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [showPass, setShowPass] = useState(false);
	const [showConfirmPass, setShowConfirmPass] = useState(false);
	const [error, setError] = useState("");
	const history = useNavigate();
	let user = getUser();
	const ekkLanguage = useSelector((state) => state.lan.lan);

	let passwordSubmitHandler = async () => {
		if (!(password && confirmPassword !== password)) {
			let req_body = {
				password: password,
				country: user.Country,
				phone_number: user.PhoneNumber.trim(),
			};

			try {
				let response = await Auth.resetPasswordWhenLogdIn(req_body);
				if (response.status === 200 || response.status === 201) {
					toast.success(ekkLanguage.changePassword.successLabel);
					history("/changed-password-success");
					setError("");
				}
			} catch (e) {
				console.log(e);
			}
		} else {
			// toast.warn("Your password and confirm password not match.");
			setError(ekkLanguage.changePassword.passNotMatchLabel);
		}
	};

	return (
		<div className='px-2 pt-24 relative change-password-screen'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='mb-10'>
							<img
								src='assets/images/password.png'
								alt=''
								className='m-auto'
							/>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 font-semibold'>
									{ekkLanguage.changePassword.passwordLabel}
								</h5>
								<IonItem>
									<IonInput
										type={!showPass ? "password" : "text"}
										value={password}
										name='password'
										placeholder={
											ekkLanguage.changePassword.passwordPlaceholder
										}
										onIonChange={(e) => setPassword(e.detail.value)}
										clearInput></IonInput>
									<IonIcon
										name={!showPass ? "eye-off" : "eye"}
										onClick={() => {
											setShowPass(!showPass);
										}}></IonIcon>
								</IonItem>
							</div>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 font-semibold'>
									{ekkLanguage.changePassword.confirmPassLabel}
								</h5>
								<IonItem>
									<IonInput
										type={!showConfirmPass ? "password" : "text"}
										value={confirmPassword}
										placeholder={
											ekkLanguage.changePassword
												.confirmPassPlaceholder
										}
										name='confirmPassword'
										onIonChange={(e) =>
											setConfirmPassword(e.detail.value)
										}
										clearInput></IonInput>
									<IonIcon
										name={!showConfirmPass ? "eye-off" : "eye"}
										onClick={() => {
											setShowConfirmPass(!showConfirmPass);
										}}></IonIcon>
								</IonItem>
								{error && (
									<div className='text-primary mt-2 text-right'>
										{error}
									</div>
								)}
							</div>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper'>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center'>
								<IonButton
									onClick={passwordSubmitHandler}
									expand='full'
									className='bg-primary rounded-[7px] font-extrabold text-12 h-10'>
									{ekkLanguage.changePassword.btnLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}
