import React, { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { IonCheckbox } from "@ionic/react";
import { getLanguage } from "config/language";
import { useDispatch, useSelector } from "react-redux";
import { parseNumber } from "libphonenumber-js";
import Auth from "services/Auth";
import { Link, useNavigate } from "react-router-dom";
import { setCreateUser } from "redux/container/userSlice";

export default function PhoneNumber({
  sSize,
  phoneNo,
  setPhoneNo,
  country,
  setCountry,
  countryCode,
  setCountryCode,
}) {
  const [isChecked, setIsChecked] = useState(false);
  const ekkLanguage = useSelector((state) => state.lan);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [spamE, setSpamE] = useState("");

  var countryList = Object.values(COUNTRYINFO).map((val) => ({
    countryCode: val.code,
    country: val.name,
    value: val.code,
    label: (
      <div className="flex items-center">
        <img
          src={val.flag}
          className="h-[20px] lg:h-auto lg:w-10 w-5 rounded-[5px]"
          alt="flag"
        ></img>{" "}
        <p className="text-14 pl-2">{val.code}</p>
      </div>
    ),
  }));
  const handleSelectCountry = (e) => {
    setCountryCode(e.countryCode);
    setCountry(e.country);
  };
  let clickBtnRef = useRef(null);
  const _handleKeyPress = (e) => {
    if (e.key === "Enter") {
      clickBtnRef.click();
    }
  };
  let customStyles = {
    option: (provided, state) => ({
      ...provided,
      zIndex: 10,
      paddingLeft: 30,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      borderWidth: 0,
      height: sSize.matches ? 50 : 60,
      borderRadius: 0,
      display: "flex",
      fontSize: sSize.matches ? 16 : 24,
    }),
    control: (provided, state) => ({
      ...provided,
      color: "red",
      borderRadius: 15,
      borderWidth: 1,
      border: "1px solid #00000026",
      outLine: "none",
      height: sSize.matches ? 50 : 60,
      paddingLeft: sSize.matches ? 10 : 20,
      fontSize: sSize.matches ? 16 : 24,
      //   borderBottomLeftRadius: 5,
      //   borderTopLeftRadius: 5,
    }),
  };
  const handleOnChange = (e) => {
    setIsChecked(!isChecked);
  };
  const errorMessage = () => {
    if (phoneNo) {
      if (country === "Bangladesh") {
        if (
          !(
            phoneNo.trim().length > 10 &&
            phoneNo.trim().length < 12 &&
            phoneNo[0] == 0
          )
        ) {
          return ekkLanguage?.lan.login.validationLabel;
        } else {
          return "";
        }
      } else {
        if (
          !Object.keys(parseNumber(countryCode + phoneNo)).length &&
          phoneNo.trim().length > 0
        ) {
          return ekkLanguage?.lan.login.validationLabel;
        } else {
          return "";
        }
      }
    }
  };

  //submit country verificarion handler, it's verify first country and phone no.
  const submitPhoneNumberCountry = async (
    countryCode,
    country,
    phoneNumber
  ) => {
    if (country === "Bangladesh") {
      if (
        !(
          phoneNo.trim().length > 10 &&
          phoneNo.trim().length < 12 &&
          phoneNo[0] == 0
        )
      ) {
        return;
      }
    }
    // if (isChecked) {
    let req_body = {
      country,
      phone_number: (countryCode + phoneNo).trim(),
    };

    try {
      let response = await Auth.countryAndUserFilter(req_body);
      console.log(response);
      if (response.status === 200 || response.status === 201) {
        let data = {
          countryCode,
          country,
          phoneNumber,
          verify: false,
        };

        if (
          response?.message === "New User Created" ||
          response?.data?.body?.user_status === "Incomplete Profile"
        ) {
          setSpamE(ekkLanguage.lan.registration.warning);
          // dispatch(setCreateUser({ ...data, verify: true }));
        } else {
          dispatch(setCreateUser(data));
          navigate("/password");
        }
      } else {
        let data = {
          countryCode,
          country,
          phoneNumber,
          verify: true,
        };
        setSpamE(response?.message);
        // dispatch(setCreateUser(data));
        // navigate("/registration");
      }
    } catch (e) {
      setSpamE(e?.errorData?.message);
      console.log(e);
    }
    // } else {
    // }
  };
  const numberInputRef = useRef(null);
  useEffect(() => {
    const handleWheel = (event) => {
      if (
        numberInputRef.current &&
        numberInputRef.current === document.activeElement
      ) {
        event.preventDefault();
      }
    };
    window.addEventListener("wheel", handleWheel, { passive: false });
    return () => {
      window.removeEventListener("wheel", handleWheel);
    };
  }, []);

  return (
    <div className=" bg-[#FFFFFF] lg:p-10 px-5 py-10 rounded-lg shadow-md lg:w-1/2 w-full">
      <label
        className={`text-14 md:text-20 text-262626-1000 sm:pb-3 pb-[10px] block`}
      >
        {ekkLanguage?.lan?.landing.phoneNumber}
      </label>
      <div
        className="flex z-10 relative"
        // style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}
      >
        <Select
          styles={customStyles}
          className="basic-single w-[250px]"
          placeholder="+88"
          name="color"
          options={countryList}
          onChange={(e) => handleSelectCountry(e)}
          components={{
            IndicatorSeparator: () => null,
          }}
          style={{ fontSize: sSize.matches ? 14 : 20 }}
          defaultValue={{
            countryCode: "+88",
            country: "Bangladesh",
            value: "+88",
            label: (
              <div className="flex items-center">
                <img
                  src="https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png"
                  className="h-[20px] lg:h-auto lg:w-10 w-5 rounded-[5px]"
                  alt="flag"
                ></img>{" "}
                <p className="text-14 pl-2">+88</p>
              </div>
            ),
          }}
        />

        <input
          ref={numberInputRef}
          type="number"
          onKeyPress={_handleKeyPress}
          onChange={(event) => {
            setSpamE("");
            setPhoneNo(event.target.value);
          }}
          placeholder={ekkLanguage?.lan?.landing.phoneNumberPlaceHolder}
          id={"ekk-input"}
          style={{
            marginLeft: 10,
            paddingLeft: 10,
            height: sSize.matches ? 50 : 60,
            flex: "flex: 1 0 auto",
            borderRadius: 15,
            borderWidth: 1,
            border: "1px solid #00000026",
          }}
          className="appearance-none text-base w-full text-grey-darker border border-left-0  py-2 px-2 leading-tight focus:outline-none focus:bg-white bg-white"
        />
      </div>
      <div className="py-4 flex justify-between items-center">
        <p className="text-red text-xs text-primary">{spamE}</p>
        <p className="text-red text-xs text-primary">{errorMessage()}</p>
      </div>

      <button
        onClick={() => {
          submitPhoneNumberCountry(countryCode, country, phoneNo);
        }}
        // propsref={(input) => {
        // 	clickBtnRef = input;
        // }}
        style={{
          background:
            "linear-gradient(89.31deg, #00C0FF 0%, rgba(0, 178, 36, 0.5) 100%)",
        }}
        className={`text-20 sm:text-26 font-bold text-white w-full h-[50px] sm:h-[60px] rounded-[5px] lg:mt-0 mt-2`}
      >
        {ekkLanguage?.lan?.landing.login}
      </button>
    </div>
  );
}
