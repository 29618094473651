import React from "react";

export default function SignOut() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<path
				d='M8.607 11.25C8.40809 11.25 8.21732 11.329 8.07667 11.4697C7.93602 11.6103 7.857 11.8011 7.857 12V14.25C7.857 14.8467 7.61995 15.419 7.19799 15.841C6.77603 16.2629 6.20374 16.5 5.607 16.5H3.75C3.15326 16.5 2.58097 16.2629 2.15901 15.841C1.73705 15.419 1.5 14.8467 1.5 14.25V3.75C1.5 3.15326 1.73705 2.58097 2.15901 2.15901C2.58097 1.73705 3.15326 1.5 3.75 1.5H5.607C6.20374 1.5 6.77603 1.73705 7.19799 2.15901C7.61995 2.58097 7.857 3.15326 7.857 3.75V6C7.857 6.19891 7.93602 6.38968 8.07667 6.53033C8.21732 6.67098 8.40809 6.75 8.607 6.75C8.80591 6.75 8.99668 6.67098 9.13733 6.53033C9.27798 6.38968 9.357 6.19891 9.357 6V3.75C9.35581 2.7558 8.96034 1.80267 8.25734 1.09966C7.55433 0.396661 6.6012 0.00119089 5.607 0H3.75C2.7558 0.00119089 1.80267 0.396661 1.09967 1.09966C0.396662 1.80267 0.00119089 2.7558 0 3.75L0 14.25C0.00119089 15.2442 0.396662 16.1973 1.09967 16.9003C1.80267 17.6033 2.7558 17.9988 3.75 18H5.607C6.6012 17.9988 7.55433 17.6033 8.25734 16.9003C8.96034 16.1973 9.35581 15.2442 9.357 14.25V12C9.357 11.8011 9.27798 11.6103 9.13733 11.4697C8.99668 11.329 8.80591 11.25 8.607 11.25Z'
				fill='#222222'
			/>
			<path
				d='M17.1503 7.40931L13.7108 3.96981C13.6416 3.89818 13.5588 3.84104 13.4673 3.80173C13.3758 3.76243 13.2774 3.74174 13.1778 3.74087C13.0782 3.74001 12.9795 3.75898 12.8873 3.79669C12.7951 3.83441 12.7114 3.89009 12.641 3.96051C12.5705 4.03093 12.5148 4.11467 12.4771 4.20685C12.4394 4.29902 12.4204 4.39778 12.4213 4.49736C12.4222 4.59695 12.4429 4.69536 12.4822 4.78686C12.5215 4.87837 12.5786 4.96113 12.6503 5.03031L15.8475 8.22756L4.5 8.25006C4.30109 8.25006 4.11032 8.32908 3.96967 8.46973C3.82902 8.61038 3.75 8.80115 3.75 9.00006C3.75 9.19897 3.82902 9.38974 3.96967 9.53039C4.11032 9.67104 4.30109 9.75006 4.5 9.75006L15.891 9.72756L12.6488 12.9698C12.5771 13.039 12.52 13.1218 12.4807 13.2133C12.4414 13.3048 12.4207 13.4032 12.4198 13.5028C12.4189 13.6023 12.4379 13.7011 12.4756 13.7933C12.5133 13.8854 12.569 13.9692 12.6395 14.0396C12.7099 14.11 12.7936 14.1657 12.8858 14.2034C12.978 14.2411 13.0767 14.2601 13.1763 14.2592C13.2759 14.2584 13.3743 14.2377 13.4658 14.1984C13.5573 14.1591 13.6401 14.1019 13.7092 14.0303L17.1488 10.5908C17.5708 10.1691 17.808 9.59699 17.8083 9.00037C17.8086 8.40375 17.5719 7.83145 17.1503 7.40931Z'
				fill='#222222'
			/>
		</svg>
	);
}
