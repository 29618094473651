import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Product = {
  productList: (type = "variant", selectedBrand, page = 1, limit = 12) => {
    return SuperFetch(
      `${
        baseUrl.BizNpdt
      }/seller/products/?page=${page}&limit=${limit}&type=${type}${
        selectedBrand ? `&brand_id=${selectedBrand}` : ""
      }`
    );
  },
  singleProductDetails: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/products/details/${id}/`);
  },
  singleOfferDetails: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/offer/details/${id}/`);
  },
  buyerSellerProductList: (buyer, seller, page = 1, limit = 12) => {
    return SuperFetch(
      `${baseUrl.product}/v4/catalogs/buyer/order/${buyer}/${seller}?page=${page}&limit=${limit}`
    );
  },
  productSearch: (tarm, page = 1, limit = 500) => {
    return SuperFetch(
      `${baseUrl.product}/v4/catalogs/buyer/discover?page=${page}&limit=${limit}&title=${tarm}&platform=Discover`
    );
  },
  // productSearch: (tarm, page = 1, limit = 500) => {
  // 	return SuperFetch(
  // 		`${baseUrl.BizNpdt}/v4/products/search/?search=${tarm}`
  // 	);
  // },

  offerProduct: (page = 1, limit = 500) => {
    return SuperFetch(
      `${baseUrl.product}/v4/offers/ekk-trading-offers?page=${page}&limit=${limit}`
    );
  },
  offerProductDetails: (id) => {
    return SuperFetch(`${baseUrl.product}/v4/offers/${id}`);
  },
  productBrands: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/businesses/${id}/brands`);
  },
  singleBrandProducts: (brandId, productId) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/businesses/${brandId}/brands/${productId}`
    );
  },
  singleProductSearch: (
    query,
    type = "variant",
    selectedBrand,
    page = 1,
    limit = 10
  ) => {
    return SuperFetch(
      `${
        baseUrl.BizNpdt
      }/seller/products/search/?q=${query}&type=${type}&page=${page}&limit=${limit}${
        selectedBrand ? `&brand_id=${selectedBrand}` : ""
      }`
    );
  },

  //new apis
  getDepartment: () => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/departments/`);
  },
  createProduct: (data) => {
    return SuperFetch(`${baseUrl.BizNpdt}/sellers/products/create/`, {
      method: "POST",
      data: data,
    });
  },
  updateProduct: (data) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/products/update/`, {
      method: "PUT",
      data: data,
    });
  },
  getCategory: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/get_category/?dept_id=${id}`);
  },
  getSubCategory: (id) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller/get_subcategory/?cat_id=${id}`
    );
  },
  deleteProduct: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/products/delete/${id}/`, {
      method: "DELETE",
    });
  },
  createPack: (data) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/packs/create/`, {
      method: "POST",
      data: data,
    });
  },
  createOffer: (data) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/offer/create/`, {
      method: "POST",
      data: data,
    });
  },
  updateOffer: (data) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/offer/update/`, {
      method: "PUT",
      data: data,
    });
  },

  getOfferList: (page = 1, limit = 12) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller/offer/?page=${page}&limit=${limit}`
    );
  },
  singleOfferSearch: (query, page = 1, limit = 10) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/seller/offer/search/?q=${query}&page=${page}&limit=${limit}`
    );
  },
  deleteOffer: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/offer/delete/${id}/`, {
      method: "DELETE",
    });
  },
};
export default Product;
