import Pen from "assets/images/icon/svg/Pen";
import Star from "assets/images/icon/svg/Star";
import DropzoneInput from "components/global-components/common/DropzoneInput";
import { digitChanger } from "helpers/helper";
import React from "react";
import { useSelector } from "react-redux";

export default function IdentityCard({ user }) {
	const userData = useSelector((state) => state.user.data);
	return (
		<div className='px-4 mb-[10px]'>
			<div className='text-center bg-white rounded-[30px] rounded-t-none pb-6'>
				<div className='pt-7 pb-4 flex items-center justify-center'>
					<div className='relative  rounded-full'>
						{userData?.profile_image ? (
							<img
								src={userData?.profile_image}
								alt='avatar'
								className='m-auto h-24 w-24 rounded-full'
							/>
						) : (
							<img
								src='assets/images/avatar.png'
								alt='avatar'
								className='m-auto h-24 w-24 rounded-full'
							/>
						)}
						<DropzoneInput />
					</div>
				</div>

				{/* <div className='flex justify-center pb-2'>
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#F94B41' />
					<Star width={15} height={15} color='#00000080' />
				</div> */}
				<h6 className='text-20 text-222222 font-semibold mb-2'>
					{userData?.full_name}
				</h6>
				<p className='text-12 font-semibold text-black-500'>
					{digitChanger(user?.phone_number)}
				</p>
			</div>
		</div>
	);
}
