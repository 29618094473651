import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import UpdateBrand from "components/mobile-components/UpdateBrand";
import React from "react";
import { useSelector } from "react-redux";

export default function UpdateBrandPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.updateBrand.pageTitle} />
					<section className='pt-14'>
						<UpdateBrand />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
