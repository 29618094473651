import { createSlice, createSelector } from "@reduxjs/toolkit";

const userSlice = createSlice({
	name: "user",
	initialState: {
		loading: false,
		data: {},
		location: false,
		locationData: null,
		bottomSheet: false,
		businessBottomSheet: false,
		myEmployees: false,
		editEmployees: false,
		myProduct: false,
		brandList: false,
		brandDetails: false,
		productDetails: false,
		pwaDownloadData: {},
		mapLocation: {},
		createUserData: {},
	},
	reducers: {
		setUser: (state, action) => {
			state.data = action.payload;
		},
		setLocation: (state, action) => {
			state.location = true;
			state.locationData = action.payload;
		},
		setBottomSheet: (state, action) => {
			state.bottomSheet = action.payload;
		},
		setBusinessBottomSheet: (state, action) => {
			state.businessBottomSheet = action.payload;
		},
		setMyEmployees: (state, action) => {
			state.myEmployees = action.payload;
		},
		setEditEmployees: (state, action) => {
			state.editEmployees = action.payload;
		},
		setBrandList: (state, action) => {
			state.brandList = action.payload;
		},
		setBrandDetails: (state, action) => {
			state.brandDetails = action.payload;
		},
		setProductDetails: (state, action) => {
			state.productDetails = action.payload;
		},
		setPwaDownloadData: (state, action) => {
			state.pwaDownloadData = action.payload;
		},
		setMapLocation: (state, action) => {
			state.mapLocation = action.payload;
		},
		setCreateUserData: (state, action) => {
			state.createUserData = action.payload;
		},
		setMyProduct: (state, action) => {
			state.myProduct = action.payload;
		},
	},
});

// Extract the action creators object and the reducer
export const { actions, reducer } = userSlice;

export const selectors = {
	getLocation: createSelector(
		(state) => state.user,
		(location) => location
	),
};

export const setLocation = (values) => async (dispatch, getState) => {
	dispatch(actions.setLocation(values));
};
export const setUser = (data) => async (dispatch, getState) => {
	dispatch(actions.setUser(data));
};
export const setBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBottomSheet(data));
};
export const setBusinessBottomSheet = (data) => async (dispatch) => {
	dispatch(actions.setBusinessBottomSheet(data));
};
export const setMyEmployees = (data) => async (dispatch) => {
	dispatch(actions.setMyEmployees(data));
};
export const setEditEmployees = (data) => async (dispatch) => {
	dispatch(actions.setEditEmployees(data));
};
export const setMyProduct = (data) => async (dispatch) => {
	dispatch(actions.setMyProduct(data));
};
export const setBrandList = (data) => async (dispatch) => {
	dispatch(actions.setBrandList(data));
};
export const setBrandDetails = (data) => async (dispatch) => {
	dispatch(actions.setBrandDetails(data));
};
export const setProductDetails = (data) => async (dispatch) => {
	dispatch(actions.setProductDetails(data));
};

export const setPwaDownloadData = (data) => async (dispatch) => {
	dispatch(actions.setPwaDownloadData(data));
};
export const setMapLocation = (data) => async (dispatch) => {
	dispatch(actions.setMapLocation(data));
};
export const eraseMapLocation = () => async (dispatch) => {
	dispatch(actions.setMapLocation({}));
};
export const setCreateUser = (data) => async (dispatch) => {
	dispatch(actions.setCreateUserData(data));
};
// Export the reducer, either as a default or named export
export default reducer;
