import Close from "assets/images/icon/svg/Close";
import Cross from "assets/images/icon/svg/Cross";
import Filter from "assets/images/icon/svg/Filter";
import SearchIcon from "assets/images/icon/svg/Search";
import React from "react";

export default function Search({
	placeholder,
	search,
	setSearch,
	type,
	singleSearch,
	filter,
	filterHandler,
	selectedFilter,
	setTempSearchParams,
}) {
	const _handleKeyPress = (e) => {
		if (e.key === "Enter") {
			singleSearch(search);
		}
	};
	const deleteQueryHandler = () => {
		setSearch("");
		setTempSearchParams("");
	};

	return (
		<div className='flex'>
			<div className='relative w-full'>
				<span className='absolute left-[10px] top-[24%] h-full z-10 cursor-pointer'>
					<SearchIcon color='rgba(0,0,0,.6)' />
				</span>
				<input
					className={`bg-white w-full h-11 rounded-[7px] pl-12 search-input border common-search $`}
					type={type}
					placeholder={placeholder}
					style={{ boxShadow: "1px 2px 8px rgba(0, 0, 0, 0.2) " }}
					value={search}
					onKeyPress={_handleKeyPress}
					onChange={(e) => {
						setSearch(e.target.value);
					}}></input>
				{search && (
					<span
						className='absolute  right-[10px] top-[34%] h-full z-10 cursor-pointer'
						onClick={deleteQueryHandler}>
						<Cross />
					</span>
				)}
			</div>
			{filter && (
				<>
					{selectedFilter ? (
						<span
							onClick={filterHandler}
							className={` bg-white rounded-[10px] flex cursor-pointer ${
								filter && "ml-2"
							}`}
							style={{
								boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
								width: "45.21px",
								height: "42px",
							}}>
							<Close />
						</span>
					) : (
						<span
							onClick={filterHandler}
							className={` bg-white rounded-[10px] flex cursor-pointer ${
								filter && "ml-2"
							}`}
							style={{
								boxShadow: "0px 3px 10px rgba(0, 0, 0, 0.1)",
								width: "45.21px",
								height: "42px",
							}}>
							<Filter />
						</span>
					)}
				</>
			)}
		</div>
	);
}
