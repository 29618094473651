import { IonCol, IonGrid, IonRow, IonSearchbar } from "@ionic/react";
import React, { useState } from "react";
import { useSelector } from "react-redux";

export default function ProductSearch({
	searchQ,
	setSearchQ,
	stickySearch,
	fetchProduct,
	singleProductSearch,
	setSelectedBrand,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonGrid>
			<IonRow>
				<IonCol size='12' className='relative'>
					<div
						className={`${
							stickySearch ? "fixed z-10 top-12" : "block z-0"
						}`}
						style={
							stickySearch
								? { width: "calc(100% - 16px)" }
								: { width: "100%" }
						}>
						<IonSearchbar
							placeholder={ekkLanguage.home.searchLabel}
							value={searchQ}
							onIonChange={(e) => {
								setSearchQ(e.target.value);
								if (!e.target.value) {
									fetchProduct();
									// setSelectedBrand("");
								}
							}}
							onKeyPress={(e) => {
								if (e.key === "Enter") {
									singleProductSearch();
								}
							}}></IonSearchbar>
					</div>
				</IonCol>
			</IonRow>
		</IonGrid>
	);
}
