import {
	IonButton,
	IonCol,
	IonGrid,
	IonInput,
	IonItem,
	IonRow,
} from "@ionic/react";
import { getUser } from "config/user";
import { updateToken } from "../../../config/SuperFetch";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Auth from "services/Auth";
import { authOtpSend } from "helpers/helper";
import { useEffect } from "react";
import { useSelector } from "react-redux";

export default function VerifyNumberSubmit() {
	let user = getUser();
	let [otp, setOtp] = useState("");
	const history = useNavigate();
	const [waiting, setWaiting] = useState(false);
	const [counter, setCounter] = useState(120);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	let time = 0;
	var interval;

	const otpSubmitHandler = async () => {
		if (otp.length > 0) {
			let req_body = {
				country: user.Country,
				phone_number: user.PhoneNumber.trim(),
				otp_token: Number(otp),
			};

			try {
				let response = await Auth.userVerifyOtp(req_body);
				if (response.success) {
					updateToken(response);
					if (response.success) {
						toast.success(ekkLanguage.otpsubmit.tokenVarifiedMessage);
						history("/change-password");
					} else {
						toast.error(response.message);
					}
				}
			} catch (e) {
				toast.error(e.errorData.message);
			}
		} else {
			toast.error(ekkLanguage.otpsubmit.validationLabel);
		}
	};

	//this function handle token resend
	const resendTokenHandler = async (phoneNumber, country, countryCode) => {
		let getToken = authOtpSend(phoneNumber, country, countryCode);
		getToken
			.then((res) => {
				if (res.status === 200) {
					toast.success(ekkLanguage.otpsubmit.successLabel);
					setWaiting(true);
					interval = setInterval(() => {
						time = time + 1;
						setCounter(counter - time);
						if (time === 120) {
							setWaiting(false);
							clearInterval(interval);
							setCounter(120);
						}
					}, 1000);
				} else {
					toast.success(ekkLanguage.otpsubmit.errorLabel);
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
	useEffect(() => {
		setWaiting(true);
		let time = 0;
		let int;
		int = setInterval(() => {
			time = time + 1;
			setCounter(counter - time);
			if (time === 120) {
				setWaiting(false);
				clearInterval(int);
				setCounter(120);
			}
		}, 1000);
	}, []);
	return (
		<div className='px-2 pt-24 relative change-password-screen'>
			<IonGrid>
				<IonRow>
					<IonCol size='12'>
						<div className='mb-10 text-center'>
							<h5 className='text-24 font-semibold text-black-1000 mb-5'>
								{ekkLanguage.otpsubmit.title}
							</h5>
							<p className='ext-14 font-normal text-black-500 mb-10'>
								{ekkLanguage.otpsubmit.paragraph}
							</p>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='flex mb-7'>
							<div className='ml-[10px] w-full'>
								<h5 className='text-dark-1000 text-16 font-semibold'>
									{ekkLanguage.otpsubmit.otpLabel}
								</h5>
								<IonItem>
									<IonInput
										type='number'
										value={otp}
										placeholder={ekkLanguage.otpsubmit.otpPlaceholder}
										onIonChange={(e) => setOtp(e.detail.value)}
										clearInput></IonInput>
								</IonItem>
							</div>
						</div>
					</IonCol>
					<IonCol size='12'>
						<div className='mb-7'>
							<div className='ml-[10px] w-full text-center'>
								{!waiting ? (
									<p className='text-dark-500 text-14 font-normal'>
										{ekkLanguage.otpsubmit.receiveYetLabel}{" "}
										<span
											className='underline text-primary font-semibold cursor-pointer'
											onClick={() =>
												resendTokenHandler(
													user.phone_number,
													user.Country,
													""
												)
											}>
											{ekkLanguage.otpsubmit.againRequestLabel}
										</span>
									</p>
								) : (
									<p className='text-dark-500 text-14 font-normal'>
										{ekkLanguage.otpsubmit.timerLabel} {counter}{" "}
										{ekkLanguage.otpsubmit.second}
									</p>
								)}
							</div>
						</div>
					</IonCol>
				</IonRow>
				<div className='submit-button-wrapper'>
					<IonRow>
						<IonCol size='12'>
							<div className='text-center'>
								<IonButton
									onClick={otpSubmitHandler}
									expand='full'
									className='bg-primary rounded-[7px] font-extrabold text-12'>
									{ekkLanguage.otpsubmit.btnLabel}
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				</div>
			</IonGrid>
		</div>
	);
}
