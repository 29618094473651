import { IonCol, IonRow } from "@ionic/react";
import { digitChanger, translator } from "helpers/helper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function ProductCard({ productItems, product }) {
	return (
		<div className='rounded-[5px] pb-2'>
			<div>
				<IonRow>
					{productItems?.map((el) => {
						let image;
						let item = el?.product;
						if (item?.images[0]) {
							image = item?.images[0];
						} else {
							if (item.type === "variant") {
								image = "/assets/images/varient_default.png";
							} else if (item.type === "pack") {
								image = "/assets/images/pack_default.png";
							} else if (item.type === "offer") {
								image = "/assets/images/offer_default.png";
							}
						}

						return (
							<IonCol size='12' sizeSm='6' key={item.id}>
								<div className='border rounded-[10px] p-2 mt-2 border-black-100 overflow-hidden relative bg-white'>
									<div className='flex items-center'>
										<div
											style={{ width: 100, height: 100 }}
											className=' bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'
										>
											<img
												src={image}
												alt='productImage'
												style={{ objectFit: "contain" }}
											/>
										</div>
										<div className='ml-3 flex-1'>
											<h2 className='text-14 font-semibold mb-3 text-black'>
												{item.title}
											</h2>
											<h3 className='text-primary text-14 font-bold mb-2'>
												{translator(product?.country?.currency_symbol_native)}{" "}
												{digitChanger(Number(item?.base_price).toFixed(2))}{" "}
												<span className='text-normal text-black-500 text-10'>
													* {digitChanger(Number(el?.quantity))} =
													{translator(product?.country?.currency_symbol_native)}{" "}
													{digitChanger(
														Number(item?.base_price * el?.quantity).toFixed(2)
													)}
												</span>
											</h3>
										</div>
									</div>
								</div>
							</IonCol>
						);
					})}
				</IonRow>
			</div>
		</div>
	);
}
