import React from "react";
import PropTypes from "prop-types";
import { ClipLoader } from "react-spinners";

export class Button extends React.PureComponent {
  static propTypes = {
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    propsRef: PropTypes.func,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    render: PropTypes.func,
    buttonClassName: PropTypes.string,
  };

  render = () => {
    const {
      loading,
      onClick,
      propsRef,
      disabled,
      title,
      render,
      style,
    } = this.props;
    //default
    const defaultBody = loading ? (
      <ClipLoader sizeUnit={"px"} size={20} color={"white"} loading={true} />
    ) : (
      <span className='text-white font-sans tracking-wide font-semibold'>
        {title}
      </span>
    );

    return (
      <button
        disabled={disabled || loading}
        ref={(input) => propsRef && propsRef(input)}
        className={"auth-box__button"}
        onClick={onClick}
        style={style}
      >
        {render ? render : defaultBody}
      </button>
    );
  };
}
