import React from "react";

export default function Box({ children }) {
	return (
		<div
			className='auth-box shadow rounded sm:rounded-none'
			style={{ minWidth: 305, maxWidth: 350 }}
		>
			<div className='auth-box__auth-brand-image'>
				<img
					alt='ekkhero hub'
					className='auth-box__auth-brand-image__element'
					src='/assets/images/hub/hub_full_logo.png'
				/>
			</div>

			{children}
		</div>
	);
}
