import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo } from "react";

function ProductFilter({ ekkLanguage, setFilteredSelections }) {
	return (
		<IonSegment
			scrollable
			className='orderBtnWrapper'
			value='variant'
			onIonChange={(e) => {
				setFilteredSelections(e.detail.value);
			}}
		>
			{/* <IonSegmentButton value='all' className=' text-black-1000 no-ripple'>
				<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.productList.all}
				</button>
			</IonSegmentButton> */}
			<IonSegmentButton value='variant' className=' text-black-1000 no-ripple'>
				<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.productList.product}
				</button>
			</IonSegmentButton>
			<IonSegmentButton value='pack' className='no-ripple'>
				<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.productList.pack}
				</button>
			</IonSegmentButton>
			<IonSegmentButton value='offer' className='no-ripple'>
				<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.productList.Offer}
				</button>
			</IonSegmentButton>

			<IonSegmentButton value='discount' className='no-ripple'>
				<button className='text-black-1000 font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.productList.discount}
				</button>
			</IonSegmentButton>
		</IonSegment>
	);
}
export default memo(ProductFilter);
