import {
  checkGetLocalUser,
  getLocalUser,
  setUserBusiness,
  setUser,
} from "config/user";
import { updateToken } from "../config/SuperFetch";

import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import Auth from "services/Auth";
import { useDispatch, useSelector } from "react-redux";
import Business from "services/Business";
// import { setKYC } from "redux/container/businessSettingsSlice";
import { toast } from "react-toastify";
export default function CheckBusiness() {
  const [business, setBusiness] = useState(getLocalUser());

  if (checkGetLocalUser()) {
    if (business?.associated_businesses.length === 0) {
      return <Navigate to="/business-type" />;
    } else if (business?.associated_businesses.length > 1) {
      return <Navigate to="/select-business" />;
    } else if (business?.associated_businesses.length === 1) {
      return <SingleBusiness />;
    }
  }
}

function SingleBusiness() {
  const navigate = useNavigate();
  const bs = useSelector((state) => state.businessSettings);
  const dispatch = useDispatch();
  async function businessSelectEvent(data) {
    try {
      if (data?.business_status !== "Black Listed") {
        const response = await Auth.businessSelect({ ...data });
        if (response.status === 200) {
          setUserBusiness(response?.business_info);
          setUser(response?.user);
          localStorage.removeItem("setUser");
          updateToken(response);
          let res = await Business.getKYC(response?.business_info?.id);
          if (res.success) {
            if (res?.results?.business_status === "Verified") {
              if (
                (response.business_info.role === "Admin") |
                (response.business_info.role === "Staff")
              ) {
                navigate("/product-list");
              } else {
                navigate("/access-restrict");
              }
            } else {
              navigate("/business-success", { replace: true });
            }
          } else {
            navigate("/business-success", { replace: true });
          }
        }
      } else {
        navigate("/black-listed")
      }
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    businessSelectEvent(getLocalUser().associated_businesses[0]);
  }, []);
  // useEffect(() => {
  // 	navigate(loginStatus ? "/dashboard" : "/");
  // }, [loginStatus]);
  return <></>;
}
