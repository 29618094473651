import { IonApp } from "@ionic/react";
import LeftArrow from "assets/images/icon/svg/LeftArrow";
import AuthLayout from "components/global-components/AuthLayout";
import ChangePassword from "components/pages-components/LogIn/ChangePassword";
import Password from "components/pages-components/LogIn/Password";
import PhoneNumberCountry from "components/pages-components/LogIn/PhoneNumberCountry";
import Registration from "components/pages-components/LogIn/Registration";
import Token from "components/pages-components/LogIn/Token";
import VerificationBox from "components/pages-components/LogIn/VerificationBox";
import { setLanguage } from "config/language";
import { authBox_Block } from "Constants";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { animated, Transition } from "react-spring/renderprops";
import { selectors } from "redux/container/authSlice";
import "../../components/pages-components/LogIn/styles/index.scss";
import DownloadPwaScreen from "./DownloadPwaModal";

const Login = (props) => {
	if (localStorage.getItem("lan") === null) {
		setLanguage("en");
	}
	const { loginStatus } = useSelector(selectors.getAuth);
	const navigate = useNavigate();
	if (loginStatus) window.location.href = "/product-list";

	let userInfo = useSelector((state) => state.user);
	const [loading, setLoading] = useState(false);
	const [blockIndex, setBlockIndex] = useState(authBox_Block.TOKEN);
	const [phoneNo, setPhoneNo] = useState(userInfo?.createUserData.phoneNumber);
	const [countryCode, setCountryCode] = useState(
		userInfo?.createUserData.countryCode
	);

	const [country, setCountry] = useState(userInfo?.createUserData.country);
	const [password, setPassword] = useState("");
	const [token, setToken] = useState("");
	const [isForgotPassword, setIsForgotPassword] = useState(false);
	const startLoading = () => setLoading(true);
	const stopLoading = () => setLoading(false);
	const [verifyNumber, setVerifyNumber] = useState(
		userInfo?.createUserData.verify
	);
	let backButtonHandler = () => {
		if (blockIndex === 2) {
			navigate(-1);
		} else if (blockIndex === 5) {
			navigate(-1);
		} else if (blockIndex === 4) {
			navigate("/");
		} else {
			setBlockIndex(blockIndex - 1);
		}
	};

	return (
		<IonApp>
			{!verifyNumber ? (
				// <AuthLayout title='Login' loading={loading}>
				<div className='mt-20'>
					<div
						className='left-5 top-5  rounded-xl z-10 backIcon cursor-pointer absolute'
						style={{
							height: 40,
							width: 40,
							boxShadow: "1px 1px 5px rgba(0,0,0,.1)",
						}}
						onClick={backButtonHandler}
					>
						<div className='h-full flex items-center justify-center'>
							<LeftArrow color={"black"} />
						</div>
					</div>
					<Transition
						native
						reset
						unique
						items={blockIndex}
						from={{
							opacity: 0,
							transform: "translate3d(100%,0,0)",
						}}
						enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
						leave={{
							opacity: 0,
							transform: "translate3d(-50%,0,0)",
						}}
					>
						{(index) => {
							if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
								return (style) => (
									<animated.div className='absolute' style={{ ...style }}>
										<PhoneNumberCountry
											loading={loading}
											phoneNo={phoneNo}
											setPhoneNo={setPhoneNo}
											countryCode={countryCode}
											setCountryCode={setCountryCode}
											country={country}
											setCountry={setCountry}
											startLoading={startLoading}
											stopLoading={stopLoading}
											setBlockIndex={setBlockIndex}
											setVerifyNumber={setVerifyNumber}
										/>
									</animated.div>
								);
							}

							if (index === authBox_Block.PASSWORD_BLOCK) {
								return (style) => (
									<animated.div className='absolute' style={{ ...style }}>
										<div>
											<Password
												setPassword={setPassword}
												loading={loading}
												setIsForgotPassword={setIsForgotPassword}
												password={password}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												startLoading={startLoading}
												stopLoading={stopLoading}
												setBlockIndex={setBlockIndex}
											/>
										</div>
									</animated.div>
								);
							}

							if (index === authBox_Block.TOKEN) {
								return (style) => (
									<animated.div className='absolute' style={{ ...style }}>
										<div>
											<Token
												loading={loading}
												setToken={setToken}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												startLoading={startLoading}
												stopLoading={stopLoading}
												setBlockIndex={setBlockIndex}
												token={token}
												isForgotPassword={isForgotPassword}
											/>
										</div>
									</animated.div>
								);
							}

							if (index === authBox_Block.CHANGE_PASS_BLOCK) {
								return (style) => (
									<animated.div className='absolute' style={{ ...style }}>
										<div>
											<ChangePassword
												loading={loading}
												phoneNo={phoneNo}
												country={country}
												countryCode={countryCode}
												startLoading={startLoading}
												stopLoading={stopLoading}
												setBlockIndex={setBlockIndex}
												setIsForgotPassword={setIsForgotPassword}
											/>
										</div>
									</animated.div>
								);
							}

							if (index === authBox_Block.REGISTRATION_BLOCK) {
								return (style) => (
									<animated.div className='absolute' style={{ ...style }}>
										<div>
											<Registration />
										</div>
									</animated.div>
								);
							}
						}}
					</Transition>
				</div>
			) : (
				<VerificationBox
					phoneNo={phoneNo}
					country={country}
					countryCode={countryCode}
					setBlockIndex={setBlockIndex}
					setVerifyNumber={setVerifyNumber}
				/>
			)}
		</IonApp>
	);
};

export default Login;
