import { baseUrl } from "config/apiUrl";
import SuperFetch from "../config/SuperFetch";

const Business = {
  getBusiness: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business_details/${id}`);
  },

  // getBranchList: (page = 1, limit = "50") => {
  // 	return SuperFetch(
  // 		`${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
  // 	);
  // },
  getBranchList: (page = 1, limit = "50") => {
    return SuperFetch(
      `${baseUrl.business}/v4/businesses/branches?page=${page}&limit=${limit}`
    );
  },
  getPaymentData: (id) => {
    return SuperFetch(
      `${baseUrl.business}/v4/businesses/bank-info/details/${id}`
    );
  },
  updateUserBusiness: (id, query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business_update/${id}/`, {
      method: "PUT",
      data: query,
    });
  },
  getEmployesList: (id, page = 1, limit = 100) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/business/employees/${id}?page=${page}&limit=${limit}`
    );
  },
  getEmployeeSearch: (query, businessId, page = 1, limit = 100) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/business/employee/search/${businessId}/?page=${page}&limit=${limit}&q=${query}`
    );
  },

  assignEmployeeToOrder: (id, query) => {
    return SuperFetch(`${baseUrl.order}/v4/orders/update/${id}`, {
      method: "PUT",
      data: query,
    });
  },
  getEmployees: (id) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/business/employee/details/?emp_id=${id}`
    );
  },
  createBusiness: (query) => {
    return SuperFetch(
      `${baseUrl.business}/v4/business-employees/business/self`,
      {
        method: "POST",
        data: query,
      }
    );
  },
  updateEmployee: (id, query) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/business/employee/update/${id}/`,
      {
        method: "PUT",
        data: query,
      }
    );
  },
  deleteEmployee: (id) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/business/employee/delete/${id}/`,
      {
        method: "DELETE",
      }
    );
  },
  updateBusinessLocation: (id, query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/seller/business/edit/${id}/`, {
      method: "PUT",
      data: query,
    });
  },
  getBusinessType: (page = 1, limit = 100) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/business_types/?page=${page}&limit=${limit}`
    );
  },
  createUserBusiness: (query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business/create/`, {
      method: "POST",
      data: query,
    });
  },
  checkBusiness: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business_check/${id}/`);
  },
  getEmployeePosition: (page = 1, limit = 20) => {
    return SuperFetch(
      `${baseUrl.BizNpdt}/ekkbaz/employee_roles/?page=${page}&limit=${limit}`
    );
  },
  createEmployee: (query) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/business/employee/create/`, {
      method: "POST",
      data: query,
    });
  },
  getKYC: (id) => {
    return SuperFetch(`${baseUrl.BizNpdt}/ekkbaz/kyc/details/${id}/`);
  },
};
export default Business;
