import { IonButton } from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ImageUpload from "../Common/ImageUpload";
import TextInput from "../Common/TextInput";
import Select from "react-select";
import { COUNTRYINFO } from "data/country";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { ClipLoader } from "react-spinners";
import User from "services/User";
import Brand from "services/Brand";
import { toast } from "react-toastify";
import { getUser, getUserBusiness } from "config/user";
import { setBrandDetails } from "redux/container/userSlice";

export default function UpdateBrand() {
	let user = getUser();
	let userBusiness = getUserBusiness();
	let { id } = useParams();
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [brandName, setBrandName] = useState("");
	const [brandDescription, setBrandDescription] = useState("");
	const [uploadImage, setUploadImage] = useState("");
	const [country, setCountry] = useState(user.country);
	const navigate = useNavigate();
	const [isOpen, setIsOpen] = useState(false);
	const [loader, setLoader] = useState(false);
	const dispatch = useDispatch();

	const onOpenModal = () => setIsOpen(true);
	const onCloseModal = () => setIsOpen(false);

	var countryList = Object.values(COUNTRYINFO).map((val) => ({
		countryCode: val.code,
		country: val.name,
		value: val.code,
		flag: val.flag,
		label: (
			<div className='flex items-center'>
				<img
					src={val.flag}
					className='h-auto w-12 rounded-[5px]'
					alt='flag'></img>{" "}
				<p className='text-16 pl-2'>{val.name}</p>
			</div>
		),
	}));

	const handleSelectCountry = (e) => {
		// setCountryCode(e.countryCode);
		setCountry(e.country);
	};
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};
	let customStyles = {
		option: (provided, state) => ({
			...provided,
			zIndex: 10,
			paddingLeft: 30,
		}),
		singleValue: (provided, state) => ({
			...provided,
			borderWidth: 0,
			height: 50,
			borderRadius: 0,
			display: "flex",
			fontSize: 16,
		}),
		control: (provided, state) => ({
			...provided,
			color: "red",
			borderRadius: 0,
			borderWidth: 0,
			outLine: "none",
			height: 50,
			paddingLeft: 10,
			fontSize: 16,
			borderBottomLeftRadius: 5,
			borderTopLeftRadius: 5,
		}),
	};

	const updateBrand = async () => {
		setLoader(true);
		let formData = new FormData();
		formData.append("key", uploadImage[0]);
		if (uploadImage[0].path) {
			let response = await User.toBucketImage(formData);
			if (response.status === 201) {
				let queryBuilder = {
					brand_name: brandName,
					description: brandDescription,
					country: country,
					images: response.data,
				};
				let res = await Brand.brandUpdate(id, queryBuilder);
				if (res.status === 200) {
					setLoader(false);
					dispatch(setBrandDetails(false));
					navigate(`/brand-update-success/${id}`);
				} else {
					setLoader(false);
				}
			}
		} else {
			let queryBuilder = {
				brand_name: brandName,
				description: brandDescription,
				country: country,
			};
			let res = await Brand.brandUpdate(id, queryBuilder);
			if (res.status === 200) {
				setLoader(false);
				dispatch(setBrandDetails(false));
				navigate(`/brand-update-success/${id}`);
			} else {
				setLoader(false);
			}
		}
	};
	let userCountry = countryList.find(
		(country) => country.country === user.country
	);
	const getBrandInfo = async () => {
		let response = await Brand.brandDetails(id);
		if (response.status === 200) {
			setBrandName(response?.results.brand_name);
			setBrandDescription(response?.results.brand_name);
			setUploadImage([{ preview: response.results.images[0] }]);
		}
	};

	useEffect(() => {
		getBrandInfo();
	}, []);
	//
	return (
		<div>
			<ImageUpload
				uploadImage={uploadImage}
				setUploadImage={setUploadImage}
			/>
			<div className='pt-[30px] px-4'>
				<TextInput
					type='text'
					title={ekkLanguage.createNewBrand.brandName}
					inputText={brandName}
					setInputText={setBrandName}
					placeholder={ekkLanguage.createNewBrand.brandNamePlaceHolder}
				/>

				<div className='pb-7 brand-list'>
					<h4 className='pb-3 font-bold text-16'>
						{ekkLanguage.createNewBrand.selectCountry}
					</h4>
					<div
						className='flex z-10 relative'
						style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}>
						<Select
							styles={customStyles}
							className='basic-single w-full'
							placeholder='+88'
							name='color'
							options={countryList}
							onChange={(e) => handleSelectCountry(e)}
							components={{
								IndicatorSeparator: () => null,
							}}
							style={{ fontSize: 14 }}
							defaultValue={{
								countryCode: "+88",
								country: "Bangladesh",
								value: "+88",
								label: (
									<div className='flex items-center'>
										<img
											src={userCountry.flag}
											className='h-auto w-12 rounded-[5px]'
											alt='flag'></img>
										<p className='text-18 pl-2 font-bold'>
											{/* {ekkLanguage.createNewBrand.selectCountry} */}
											{country}
										</p>
									</div>
								),
							}}
						/>
					</div>
				</div>

				<TextInput
					textarea={true}
					title={ekkLanguage.createNewBrand.description}
					inputText={brandDescription}
					setInputText={setBrandDescription}
					placeholder={ekkLanguage.createNewBrand.descriptionPlaceHolder}
				/>
				<div className='text-center pb-[60px]'>
					<p className='text-black-333 text-14 pb-[10px]'>
						{ekkLanguage.createNewBrand.createdBy}
					</p>
					<h5 className='text-black-1000 text-16 font-bold'>
						{userBusiness?.business_name}
					</h5>
				</div>
				<div className='mb-12'>
					<IonButton
						onClick={() => {
							if (brandName) {
								onOpenModal();
							} else {
								toast.warning("Please write a brand name");
							}
						}}
						style={{ boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.15)" }}
						expand='block'
						className='bg-success rounded-[5px] h-12 font-sans font-bold text-24'>
						{ekkLanguage.createNewBrand.complete}
					</IonButton>
				</div>
			</div>
			{/* order conformation modal*/}
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpen}
				onRequestClose={onCloseModal}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.updateBrand.conformModal}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModal}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							color='primary'
							className={`${
								loader && "select-none cursor-no-drop"
							} font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}
							onClick={!loader && updateBrand}>
							{loader ? (
								<ClipLoader
									sizeUnit={"px"}
									size={20}
									color={"white"}
									loading={true}
								/>
							) : (
								ekkLanguage.orderDetails.yesBtn
							)}
						</button>
					</div>
				</div>
			</Modal>
		</div>
	);
}
