import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import UpdateOffer from "components/mobile-components/UpdateOffer";
import React from "react";
import { useSelector } from "react-redux";

export default function UpdateOfferPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.updateOffer.pageTitle} />
					<section className='pt-14'>
						<UpdateOffer />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
