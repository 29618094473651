import { IonCol } from "@ionic/react";
import Cross from "assets/images/icon/svg/Cross";
import Minus from "assets/images/icon/svg/Minus";
import Plus from "assets/images/icon/svg/Plus";
import { digitChanger, translator } from "helpers/helper";
import React from "react";
import { useDispatch } from "react-redux";
import { productOfferUpdate } from "redux/container/productSlice";

export default function SingleProductForCard({ image, item, offerList }) {
	const dispatch = useDispatch();

	const updateProduct = (el, action, qty) => {
		let updatedProductItems = [];

		switch (action) {
			case "minus":
				updatedProductItems = offerList.productItems?.map(
					({ product, quantity, offer_item_id }) => {
						if (product.id === el.id) {
							return {
								product: {
									...product,
									orderQuantity:
										product.orderQuantity > 1
											? Number(product.orderQuantity) - 1
											: 1,
								},
								quantity: quantity > 1 ? quantity - 1 : 1,
								offer_item_id,
							};
						} else {
							return { product, quantity, offer_item_id };
						}
					}
				);
				break;

			case "plus":
				updatedProductItems = offerList.productItems?.map(
					({ product, quantity, offer_item_id }) => {
						if (product.id === el.id) {
							return {
								product: {
									...product,
									orderQuantity: Number(product.orderQuantity) + 1,
								},
								quantity: quantity + 1,
								offer_item_id,
							};
						} else {
							return { product, quantity, offer_item_id };
						}
					}
				);
				break;

			case "edit":
				updatedProductItems = offerList.productItems?.map(
					({ product, quantity, offer_item_id }) => {
						if (product.id === el.id) {
							return {
								product: {
									...product,
									orderQuantity: qty > -1 ? Number(qty) : 1,
								},
								quantity: qty > -1 ? Number(qty) : 1,
								offer_item_id,
							};
						} else {
							return { product, quantity, offer_item_id };
						}
					}
				);
				break;

			case "delete":
				updatedProductItems = offerList.productItems?.filter(
					({ product }) => product?.id !== el.id
				);
				break;

			default:
				updatedProductItems = offerList.productItems;
				break;
		}

		const updatedOfferList = {
			...offerList,
			productItems: updatedProductItems,
		};

		dispatch(productOfferUpdate(updatedOfferList));
	};

	return (
		<IonCol size='12' className='p-0' key={item?.id}>
			<div className='border rounded-[10px] p-2 mt-5 border-black-100 overflow-hidden relative'>
				{/* <span
					className=' absolute right-2 top-2 font-semibold cursor-pointer'
					onClick={() => updateProduct(item, "delete")}>
					<Cross />
				</span> */}
				<div className='flex items-center'>
					<div
						style={{ width: 100, height: 100 }}
						className=' bg-F3F3F3 rounded-[10px] text-center flex items-center justify-center overflow-hidden'>
						<img
							src={image}
							alt='productImage'
							style={{ objectFit: "contain" }}
						/>
					</div>
					<div className='ml-3 flex-1'>
						<h2 className='text-14 font-semibold mb-3 text-black'>
							{item?.title}
						</h2>

						<h3 className='text-primary text-14 font-bold mb-2'>
							{item?.productCountry?.currency_symbol_native}{" "}
							{digitChanger(Number(item?.base_price).toFixed(2))}{" "}
							<span className='text-normal text-black-500 text-10'>
								* {digitChanger(Number(item?.orderQuantity))} =
								{item?.productCountry?.currency_symbol_native}{" "}
								{digitChanger(
									Number(
										item?.base_price * item?.orderQuantity
									).toFixed(2)
								)}
							</span>
						</h3>
						<div className='flex'>
							<div>
								<span
									className={`bg-primary w-8 h-9 flex justify-center items-center rounded-md leading-6 select-none focus:outline-none ${
										item?.orderQuantity <= 1
											? "cursor-not-allowed "
											: " cursor-pointer"
									}`}
									style={
										item?.orderQuantity <= 1
											? {
													boxShadow:
														"0px 3px 10px rgba(0,0,0,.15)",
													opacity: 0.5,
											  }
											: {
													boxShadow:
														"0px 3px 10px rgba(0,0,0,.15)",
													opacity: 1,
											  }
									}
									onClick={() => {
										updateProduct(item, "minus");
									}}>
									<Minus />
								</span>
							</div>

							<div className='w-full px-3'>
								<input
									className={`appearance-none w-full rounded-md h-9 leading-6 text-center ${
										item?.orderQuantity > 0
											? "bg-F3F3F3"
											: "bg-F94B41-200"
									}  `}
									type='number'
									value={item?.orderQuantity}
									onChange={(e) => {
										updateProduct(item, "edit", e.target.value);
									}}></input>
							</div>
							<div>
								<span
									className='bg-success w-8 h-9 flex justify-center items-center rounded-md leading-6 cursor-pointer select-none'
									style={{
										boxShadow: "0px 3px 10px rgba(0,0,0,.15)",
									}}
									onClick={() => updateProduct(item, "plus")}>
									<Plus />
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</IonCol>
	);
}
