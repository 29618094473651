import EyeClose from "assets/images/icon/svg/EyeClose";
import EyeOpen from "assets/images/icon/svg/EyeOpen";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import CancelPopUp from "assets/images/icon/svg/CancelPopUp";
import { useSelector } from "react-redux";
import { digitChanger } from "helpers/helper";

export default function SingleProduct({
	item,
	setSelectedItem,
	onOpenModal,
	onOpenUnPublishModal,
}) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [isOpenPublish, setIsOpenPublish] = useState(false);
	const onOpenModalPublish = () => setIsOpenPublish(true);
	const onCloseModalPublish = () => setIsOpenPublish(false);
	const customModalStyles = {
		content: {
			top: "50%",
			left: "50%",
			right: "auto",
			bottom: "auto",
			marginRight: "-50%",
			transform: "translate(-50%, -50%)",
			padding: "0px",
			width: "300px",
			transition: ".4s all ease",
			borderRadius: 12,
			// height: "calc(100% - 60px)",
		},
		overlay: {
			position: "fixed",
			top: 0,
			left: 0,
			right: 0,
			bottom: 0,
			transition: ".4s all ease",
			backgroundColor: "rgba(0,0,0,.5)",
		},
	};

	const navigate = useNavigate();
	return (
		<>
			<div className='relative overflow-hidden'>
				{item.type === "offer" ? (
					<span
						onClick={(e) => {
							setSelectedItem(item);
							if (item.base_price && item.inventory_quantity) {
								if (item.publish_status === "Unpublish") {
									onOpenModal();
								} else {
									onOpenUnPublishModal();
								}
							} else {
								if (item.publish_status === "Publish") {
									onOpenUnPublishModal();
								} else {
									onOpenModalPublish();
								}
							}
						}}
						className={`absolute h-[25px] w-[35px] ${
							item.publish_status !== "Unpublish"
								? "bg-success"
								: "bg-primary"
						}  flex justify-center items-center top-0 right-0`}
						style={{
							borderBottomLeftRadius: 5,
							borderTopRightRadius: 5,
							zIndex: 5,
						}}>
						{item.publish_status !== "Unpublish" ? (
							<EyeOpen />
						) : (
							<EyeClose />
						)}
					</span>
				) : (
					<span
						onClick={(e) => {
							setSelectedItem(item);
							if (item.base_price && item.inventory_quantity) {
								if (item.status === "Unpublish") {
									onOpenModal();
								} else {
									onOpenUnPublishModal();
								}
							} else {
								if (item.status === "Publish") {
									onOpenUnPublishModal();
								} else {
									onOpenModalPublish();
								}
							}
						}}
						className={`absolute h-[25px] w-[35px] ${
							item.status !== "Unpublish" ? "bg-success" : "bg-primary"
						}  flex justify-center items-center top-0 right-0`}
						style={{
							borderBottomLeftRadius: 5,
							borderTopRightRadius: 5,
							zIndex: 5,
						}}>
						{item.status !== "Unpublish" ? <EyeOpen /> : <EyeClose />}
					</span>
				)}

				<div
					className='p-[10px] bg-white rounded-[5px] mb-4 relative overflow-hidden cursor-pointer'
					style={{ boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.05)" }}
					onClick={() =>
						navigate(`/product-details/${item.type}/${item.id}`)
					}>
					<h4 className='pr-10 font-medium text-12 text-black-1000 pb-[7px]'>
						{item?.title ?? item.name}
					</h4>
					<div>
						<ul className='flex justify-between items-center'>
							<li className='flex'>
								{item.type === "variant" && (
									<img
										src='assets/images/package-box.png'
										alt='icon'
									/>
								)}
								{item.type === "pack" && (
									<img
										src='assets/images/product-small.png'
										alt='icon'
									/>
								)}
								{item.type === "offer" && (
									<img
										src='assets/images/discount-icon.png'
										alt='icon'
									/>
								)}
								{/* 
						<img src='assets/images/icon/product-small.png' alt='icon' />
						<img src='assets/images/icon/discount-icon.png' alt='icon' /> */}

								<span className='font-bold text-12 text-primary pl-1'>
									{item?.country?.currency_symbol_native}
									{digitChanger(
										Number(item?.consumer_price).toFixed(2)
									)}{" "}
								</span>
							</li>
							<li className='flex'>
								<img src='assets/images/warehouse.png' alt='icon' />
								<span className='font-bold text-12 text-primary pl-1'>
									{digitChanger(item?.inventory_quantity)}
								</span>
							</li>
							<li className='flex'>
								<img src='assets/images/profits.png' alt='icon' />
								<span className='font-bold text-12 text-paid pl-1'>
									{item?.country?.currency_symbol_native}
									{digitChanger(
										Number(
											Number(item?.base_price ?? 0) -
												Number(item?.purchase_price ?? 0)
										).toFixed(2)
									)}
								</span>
							</li>
						</ul>
					</div>
				</div>
			</div>
			<Modal
				style={customModalStyles}
				closeTimeoutMS={200}
				isOpen={isOpenPublish}
				onRequestClose={onCloseModalPublish}
				classNames='bottom-sheet-modal'>
				<div className='p-5'>
					<span className='mb-2 inline-block mx-2'>
						<CancelPopUp />
					</span>
					<h6 className='text-16 font-bold pb-[10px]'>
						{ekkLanguage.createProduct.updateMessage}
					</h6>

					<div className='flex'>
						<button
							className='border-2 font-bold h-10 rounded-[7px] w-1/2 mr-2 text-black-1000 border-black-200 border-solid'
							onClick={onCloseModalPublish}>
							{ekkLanguage.orderDetails.noBtn}
						</button>
						<button
							onClick={() => navigate(`/update-product/${item.id}`)}
							color='primary'
							className={` font-bold h-10 rounded-[7px] w-1/2 ml-2 border-2 bg-primary text-white flex justify-center items-center`}>
							{ekkLanguage.createProduct.updateBtn}
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
}
