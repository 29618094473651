import { IonItem } from "@ionic/react";

import React from "react";
import Sheet from "react-modal-sheet";
import { useDispatch, useSelector } from "react-redux";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router-dom";
import { setBrandList } from "redux/container/userSlice";

export default function BrandBottomSheet() {
	const ref = React.useRef();
	const isOpenBottomSheet = useSelector((state) => state.user.brandList);
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	return (
		<div>
			<Sheet
				className='bottom-sheet'
				initialSnap={1}
				ref={ref}
				isOpen={isOpenBottomSheet}
				snapPoints={[260, 260, 100, 0]}
				onClose={() => dispatch(setBrandList(false))}>
				<Sheet.Container>
					<Sheet.Header />
					<Sheet.Content>
						<IonItem
							button
							onClick={() => navigate("/create-brand")}
							lines='none'>
							<div className='flex items-center'>
								<span className='py-[5px] pr-4'>
									<img
										className='h-10 w-10'
										src='assets/images/createBrand.png'
										alt='brand'
									/>
								</span>
								<p className='text-16 font-bold text-black-1000 pl-2'>
									{ekkLanguage.brandList.createNewBrand}
								</p>
							</div>
						</IonItem>
					</Sheet.Content>
				</Sheet.Container>

				<Sheet.Backdrop onClick={() => dispatch(setBrandList(false))} />
			</Sheet>
		</div>
	);
}
