import Login from "pages/Auth/Login";
import Landing from "pages/Landing";
import ChangeLanguagePage from "pages/MobilePage/ChangeLanguagePage";
import ChangePasswordPage from "pages/MobilePage/ChangePasswordPage";
import ChangePasswordSuccessPage from "pages/MobilePage/ChangePasswordSuccessPage";
import ContactPage from "pages/MobilePage/ContactPage";
import Error from "pages/MobilePage/Error";
import Map from "pages/MobilePage/Map";
import MySupplierPage from "pages/MobilePage/MySupplierPage";
import PrivacyPolicy from "pages/MobilePage/PrivacyPolicy";
import ProductDetailsPage from "pages/MobilePage/ProductDetailsPage";
import ProfilePage from "pages/MobilePage/ProfilePage";
import ProfileVarifyOtpPage from "pages/MobilePage/ProfileVarifyOtpPage";
import ProfileVarifyPage from "pages/MobilePage/ProfileVarifyPage";
import RefundPolicy from "pages/MobilePage/RefundPolicy";
import SearchPage from "pages/MobilePage/SearchPage";
import SeeMoreProductPage from "pages/MobilePage/SeeMoreProductPage";
import SelectBusiness from "pages/MobilePage/SelectBusiness";
import SelectProductPage from "pages/MobilePage/SelectProductPage";
import TermsCondition from "pages/MobilePage/TermsCondition";
import { Route, Routes } from "react-router-dom";
import ProductListPage from "pages/MobilePage/ProductListPage";
import BrandListPage from "pages/MobilePage/BrandListPage";
import CreateBrandSuccessPage from "pages/MobilePage/CreateBrandSuccessPage";
import CreateProductPage from "pages/MobilePage/CreateProductPage";
import UpdateBrandPage from "pages/MobilePage/UpdateBrandPage";
import UpdateBrandSuccessPage from "pages/MobilePage/UpdateBrandSuccessPage";
import MyBrandListPage from "pages/MobilePage/MyBrandListPage";
import UpdateProductPage from "pages/MobilePage/UpdateProductPage";
import CreatePackPage from "pages/MobilePage/CreatePackPage";
import CreateOfferPage from "pages/MobilePage/CreateOfferPage";
import UpdateOfferPage from "pages/MobilePage/UpdateOfferPage";
import PrivateOutlet from "./PrivateOutlet";
import CheckBusiness from "components/CheckBusiness";
import AutoLogoutPage from "pages/MobilePage/AutoLogoutPage";
import AccessRestrictPage from "pages/MobilePage/AccessRestrict";
import BlackListedPage from "pages/MobilePage/BlackListedPage";
import PhoneNumberCheck from "pages/LandingPageV2/PhoneNumberCheck";
import PasswordV2 from "pages/LandingPageV2/PasswordV2";

function Mobile() {
	return (
		<Routes>
			<Route path='/*' element={<PrivateOutlet />}>
				<Route
					path='product-details/:type/:id'
					element={<ProductDetailsPage />}
				/>
				<Route path='product-list' element={<ProductListPage />} />
				<Route path='location' element={<Map />} />
				<Route path='select-product/:type' element={<SelectProductPage />} />
				<Route path='select-business' element={<SelectBusiness />} />

				<Route path='profile' element={<ProfilePage />} />
				<Route path='contact' element={<ContactPage />} />
				<Route path='change-password' element={<ChangePasswordPage />} />
				<Route
					path='changed-password-success'
					element={<ChangePasswordSuccessPage />}
				/>
				<Route path='products/:category' element={<SeeMoreProductPage />} />
				<Route path='otp-send' element={<ProfileVarifyPage />} />
				<Route path='otp-submit' element={<ProfileVarifyOtpPage />} />
				<Route path='change-language' element={<ChangeLanguagePage />} />
				<Route path='search-page/:tarm' element={<SearchPage />} />
				<Route path='mysupplier' element={<MySupplierPage />} />

				<Route path='brand-list' element={<BrandListPage />} />
				<Route path='my-brand' element={<MyBrandListPage />} />
				<Route path='update-brand/:id' element={<UpdateBrandPage />} />
				<Route path='create-product' element={<CreateProductPage />} />
				<Route path='create-pack' element={<CreatePackPage />} />
				<Route path='create-offer' element={<CreateOfferPage />} />
				<Route path='update-product/:id' element={<UpdateProductPage />} />
				<Route path='update-offer/:id' element={<UpdateOfferPage />} />
				<Route
					path='brand-create-success'
					element={<CreateBrandSuccessPage />}
				/>
				<Route
					path='brand-update-success/:id'
					element={<UpdateBrandSuccessPage />}
				/>
				<Route path='*' element={<Error />} />
			</Route>
			<Route path='/auto-log-out' element={<AutoLogoutPage />} />
			<Route path='black-listed' element={<BlackListedPage />} />
			<Route path='access-restrict' element={<AccessRestrictPage />} />
			{/* <Route path='/login' element={<Login />} />
			<Route path='/' element={<Landing />} /> */}
			<Route path="/" element={<PhoneNumberCheck />} />
			<Route path="/password" element={<PasswordV2 />} />
			<Route path='/business-check' element={<CheckBusiness />} />
			<Route path='/refund-policy' element={<RefundPolicy />} />
			<Route path='/privacy-policy' element={<PrivacyPolicy />} />
			<Route path='/terms-conditions' element={<TermsCondition />} />
		</Routes>
	);
}

export default Mobile;
