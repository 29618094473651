import Pen from "assets/images/icon/svg/Pen";
import { getUser } from "config/user";
import React, { useEffect, useState, useRef } from "react";
import User from "services/User";
import { setUser } from "redux/container/userSlice";
import { useDispatch } from "react-redux";
import { IonSpinner } from "@ionic/react";

const DropzoneInput = ({ name = "image" }) => {
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState(null);
  const user = getUser();
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  useEffect(() => {
    return () => {
      // Make sure to revoke the data URIs to avoid memory leaks
      files.forEach((file) => URL.revokeObjectURL(file.preview));
    };
  }, [files]);

  let formData = new FormData();

  useEffect(() => {
    const uploadFile = async () => {
      formData.append("key", files[0]);
      if (files.length !== 0) {
        setLoading(true);
        try {
          let response = await User.toBucketImage(formData);
          if (response.status === 200 || response.status === 201) {
            let reqbody = {
              profile_image: response.results[0],
            };
            let resp = await User.basicInfoUpdate(user._id, reqbody);
            if (resp.status === 200 || resp.status === 201) {
              let res = await User.getBasicInfoUpdate();
              if (res.status === 200 || res.status === 201) {
                dispatch(setUser(res.data));
                setLoading(false);
              }
            }
            setValue(name, response.results[0]);
          }
        } catch (error) {
          // Handle error
          console.error("Error:", error);
        }
      }
    };
    uploadFile();
  }, [files]);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleFileChange = (event) => {
    const selectedFiles = event.target.files;
    setFiles(
      Array.from(selectedFiles).map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  };

  return (
    <section>
      <div className="dropzone" onClick={handleClick}>
        <input
          type="file"
          ref={inputRef}
          accept="image/jpeg, image/png, image/jpg"
          style={{ display: "none" }}
          onChange={handleFileChange}
        />
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50 rounded-full">
            <IonSpinner />
          </div>
        )}
        {!loading && (
          <span
            className="absolute h-7 w-7 rounded-full text-center flex items-center justify-center right-1 bottom-0 border-4 border-white"
            style={{ background: "#5A7EFF", lineHeight: "28px" }}
          >
            <Pen />
          </span>
        )}
      </div>
    </section>
  );
};

export default DropzoneInput;
