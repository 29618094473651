import { IonApp, IonContent, IonPage } from "@ionic/react";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import CreatePack from "components/mobile-components/CreatePack";
import React from "react";
import { useSelector } from "react-redux";

export default function CreatePackPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle title={ekkLanguage.createPack.pageTitle} />
					<section className='pt-14'>
						<CreatePack />
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
