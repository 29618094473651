import { IonButton } from "@ionic/react";
import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
export default function SelectProduct({ type }) {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div
			className='bg-white fixed bottom-0 left-0 w-full p-2 pb-2 z-10'
			style={{ boxShadow: "0px -2px 15px rgba(0, 0, 0, 0.1)" }}>
			<div>
				<Link to={`/create-${type}`}>
					<IonButton
						expand='block'
						className='bg-primary rounded-xl h-12 font-sans font-bold shadow-none text-20'>
						{ekkLanguage.selectProduct.btnLabel}
					</IonButton>
				</Link>
			</div>
		</div>
	);
}
