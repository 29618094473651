import React, { useState } from "react";
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonItem,
  IonLabel,
  IonThumbnail,
  useIonLoading,
  IonLoading,
  IonApp,
} from "@ionic/react";
import {
  getLocalUser,
  setLocalUser,
  setUser,
  setUserBusiness,
} from "config/user";
import { useNavigate } from "react-router-dom";
import Auth from "services/Auth";
import { updateToken } from "../../config/SuperFetch";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import { BUSINESS_TYPE } from "data/businessType";
import { useDispatch, useSelector } from "react-redux";
// import { setKYC } from "redux/container/businessSettingsSlice";
import Business from "services/Business";
import { toast } from "react-toastify";

export default function SelectBusiness() {
  const [business, setBusiness] = useState(getLocalUser());
  const navigate = useNavigate();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const dispatch = useDispatch();

  async function businessSelectEvent(data) {
    try {
      if (data?.business_status !== "Black Listed") {
        const response = await Auth.businessSelect(data);
        setUserBusiness(response?.business_info);
        setUser(response?.user);
        localStorage.removeItem("setUser");
        updateToken(response);
        // navigate("/dashboard", { replace: true });
        let res = await Business.getKYC(response.business_info?.id);

        if (res.success) {
          // add kyc
          // dispatch(
          // 	setKYC({
          // 		kycUpdate: true,
          // 		nid_back_img: res.results?.nid_back_img,
          // 		nid_front_img: res.results?.nid_front_img,
          // 		rent_amount: res.results?.rent_amount,
          // 		rent_start_date: res.results?.rent_start_date,
          // 		property_type: res.results?.property_type,
          // 		trade_img: res.results?.trade_img,
          // 		contract_paper_img: res.results?.contract_paper_img,
          // 		business: response.business_info?.id,
          // 		kycId: res.results?.id,
          // 		business_status: res.results?.business_status,
          // 	})
          // );
          if (res.results?.business_status === "Verified") {
            if (
              (response.business_info.role === "Admin") |
              (response.business_info.role === "Staff")
            ) {
              navigate("/product-list");
            } else {
              navigate("/access-restrict");
            }
          } else {
            navigate("/business-success", { replace: true });
          }
        } else {
          // dispatch(
          // 	setKYC({
          // 		kycUpdate: false,
          // 		business_status: response.business_info?.business_status,
          // 		business: response.business_info?.id,
          // 	})
          // );
          navigate("/business-success", { replace: true });
        }
      } else {
        navigate("/black-listed");
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <IonApp>
      <IonPage className="business-name pt-14">
        <IonContent>
          <BackButtonTitle title={ekkLanguage.businessSelect.pageTitle} />
          <div className="pb-4 pt-4">
            <div className="px-4 pb-4">
              <h4 className="font-bold">{ekkLanguage.home.account}</h4>
            </div>
            {business?.associated_businesses.map((select, i) => {
              BUSINESS_TYPE.forEach((el) => {
                if (el.type === select.business_type) {
                  select.img = el.image;
                }
              });
              return (
                <IonItem
                  key={i}
                  button
                  onClick={() => {
                    businessSelectEvent(select);
                  }}
                  lines="none"
                  className="mb-4"
                >
                  <div className="flex py-[5px] px-4">
                    <div className="w-12 h-12 p-2 flex justify-center items-center bg-F3F3F3 rounded-[10px] mr-5">
                      <img
                        src={select.img}
                        className="w-7 h-7"
                        alt="busnissLogo"
                      />
                    </div>
                    <IonLabel>
                      <h3 className="text-16 text-black-1000 font-normal">
                        {select?.business_name}
                      </h3>
                      <p className="text-12 text-black-500 font-normal">
                        {select?.business_type} - {select?.business_status} -{" "}
                        {select.role}
                      </p>
                    </IonLabel>
                  </div>
                </IonItem>
              );
            })}
          </div>
        </IonContent>
      </IonPage>
    </IonApp>
  );
}
