import Chart from "assets/images/icon/svg/Chart";
import Download2 from "assets/images/icon/svg/Download2";
import Employees from "assets/images/icon/svg/Employees";
import CheckBusiness from "components/CheckBusiness";
import { getUser, getUserBusiness } from "config/user";
import DeviceDetector from "device-detector-js";
import { digitChanger, hasActive } from "helpers/helper";
import { useEffect, useState } from "react";
import Modal from "react-modal";
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useLocation } from "react-router-dom";
import { setPwaDownloadData } from "redux/container/userSlice";
import Scrollbar from "smooth-scrollbar";
import "./styles/_SidebarComponent.scss";
import Product from "assets/images/icon/svg/Product";

const LeftSidebar = ({ isMobile, menuCollapse, menuIconClick }) => {
  const { pathname } = useLocation();
  let dispatch = useDispatch();
  const ekkLanguage = useSelector((state) => state.lan.lan);
  const pwaObject = useSelector((state) => state.user);
  const [isOpen, setIsOpen] = useState(false);
  let user = getUser();
  let userBusiness = getUserBusiness();
  const userData = useSelector((state) => state.user.data);

  useEffect(() => {
    Modal.setAppElement("body");
    Scrollbar.init(document.querySelector("#sidebar-scrollbar"));
  });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handler = (e) => {
    e.preventDefault();
    dispatch(setPwaDownloadData(e));
  };
  useEffect(() => {
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("beforeinstallprompt", handler);
  }, [pathname, handler]);

  const onClick = () => {
    if (!pwaObject.pwaDownloadData) {
      return;
    }
    pwaObject.pwaDownloadData.prompt();
    setIsOpen(false);
  };
  const onCloseModal = () => {
    setIsOpen(false);
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "0px",
      width: "300px",
      transition: ".4s all ease",
      borderRadius: 12,
    },
    overlay: {
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: ".4s all ease",
      backgroundColor: "rgba(0,0,0,.5)",
    },
  };
  const deviceDetector = new DeviceDetector();
  const userAgent = navigator.userAgent;
  const device = deviceDetector.parse(userAgent);
  return (
    <>
      <CheckBusiness />
      <div
        onClick={menuIconClick}
        className={`back-drop w-full h-full bg-black-1000 z-[100]  opacity-40 transition-opacity ${
          menuCollapse
            ? "opacity-0 invisible absolute"
            : "opacity-40 visible fixed"
        }`}
      ></div>
      <div
        className={`sidebar ${isMobile ? "mobiel-sidebar" : ""}`}
        style={
          menuCollapse
            ? {
                width: 0,
                zIndex: 1000000,
              }
            : {
                width: "300px",
                zIndex: 1000000,
              }
        }
      >
        <div id="sidebar-scrollbar">
          <span
            className="absolute top-2 right-2 bg-primary text-white p-1 rounded-[5px]"
            style={{ zIndex: 1111, lineHeight: 1 }}
            onClick={menuIconClick}
          >
            <i className="material-icons">close</i>
          </span>
          <nav className="sidebar-menu">
            <ProSidebar collapsed={menuCollapse} className="relative">
              {!menuCollapse && (
                <SidebarHeader>
                  <Link to="/profile">
                    <div className="logo-box flex items-center pt-[30px] px-5 pb-5">
                      <div className="user-img mr-[10px] ">
                        {userData && userData?.profile_image ? (
                          <img
                            src={userData?.profile_image}
                            alt="avatar"
                            className="h-[80px] w-[80px] rounded-full"
                          />
                        ) : (
                          <img
                            src={user?.profile_image}
                            alt="user_avater"
                            className="h-[80px] w-[80px] rounded-full"
                          />
                        )}
                      </div>
                      <div className="user-info">
                        <p className="text-16 font-semibold text-222222 pb-[5px]">
                          {user?.full_name}
                        </p>
                        <p className="text-10 pb-[5px]">
                          {digitChanger(user?.PhoneNumber)}
                        </p>
                        <p className="text-10 pb-[5px]">
                          {userBusiness?.business_name}
                        </p>
                        <span className="text-12">
                          {userBusiness?.business_type?.title}
                          {" - "}
                          <span className="text-success">
                            {userBusiness?.business_status}
                          </span>
                          {" - "}
                          {userBusiness?.role}
                        </span>
                      </div>{" "}
                    </div>
                  </Link>
                </SidebarHeader>
              )}
              <SidebarContent>
                <Menu iconShape="square">
                  <MenuItem
                    className={hasActive("/product-list", pathname)}
                    icon={<Product />}
                  >
                    {ekkLanguage.letNav.product}
                    <NavLink to={`/product-list`} />
                  </MenuItem>
                </Menu>
              </SidebarContent>
            </ProSidebar>
          </nav>
        </div>
      </div>
    </>
  );
};

export default LeftSidebar;
