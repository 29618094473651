import React from "react";

export default function Globe() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9 0C4.05 0 0 4.05 0 9C0 13.95 4.05 18 9 18C13.95 18 18 13.95 18 9C18 4.05 13.95 0 9 0ZM15.21 5.4H12.6C12.33 4.23 11.88 3.24 11.34 2.16C12.96 2.79 14.4 3.87 15.21 5.4ZM9 1.8C9.72 2.88 10.35 4.05 10.71 5.4H7.29C7.65 4.14 8.28 2.88 9 1.8ZM2.07 10.8C1.89 10.26 1.8 9.63 1.8 9C1.8 8.37 1.89 7.74 2.07 7.2H5.13C5.04 7.83 5.04 8.37 5.04 9C5.04 9.63 5.13 10.17 5.13 10.8H2.07ZM2.79 12.6H5.4C5.67 13.77 6.12 14.76 6.66 15.84C5.04 15.21 3.6 14.13 2.79 12.6ZM5.4 5.4H2.79C3.69 3.87 5.04 2.79 6.66 2.16C6.12 3.24 5.67 4.23 5.4 5.4ZM9 16.2C8.28 15.12 7.65 13.95 7.29 12.6H10.71C10.35 13.86 9.72 15.12 9 16.2ZM11.07 10.8H6.93C6.84 10.17 6.75 9.63 6.75 9C6.75 8.37 6.84 7.83 6.93 7.2H11.16C11.25 7.83 11.34 8.37 11.34 9C11.34 9.63 11.16 10.17 11.07 10.8ZM11.34 15.84C11.88 14.85 12.33 13.77 12.6 12.6H15.21C14.4 14.13 12.96 15.21 11.34 15.84ZM12.96 10.8C13.05 10.17 13.05 9.63 13.05 9C13.05 8.37 12.96 7.83 12.96 7.2H16.02C16.2 7.74 16.29 8.37 16.29 9C16.29 9.63 16.2 10.26 16.02 10.8H12.96Z'
				fill='#222222'
			/>
		</svg>
	);
}
