import {
	IonApp,
	IonContent,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonPage,
} from "@ionic/react";
import BrandList from "components/mobile-components/BrandList";
import BrandBottomSheet from "components/mobile-components/BrandList/BrandBottomSheet";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import React, { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Brand from "services/Brand";

export default function BrandListPage() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	const [brandList, setBrandList] = useState([]);
	const [myBrand, setMyBrand] = useState(true);
	const [pageCount, setPageCount] = useState(2);
	const [searchQ, setSearchQ] = useState("");

	const getMyBrandList = async () => {
		setPageCount(2);
		if (searchQ) {
			let response = await Brand.getMyBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		} else {
			let response = await Brand.getMyBrandList();
			if (response.status === 200) {
				setBrandList(response.results);
			}
		}
	};
	const getAllBrandList = async () => {
		setPageCount(2);
		if (searchQ) {
			let response = await Brand.getAllBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		} else {
			let response = await Brand.getAllBrandList();
			if (response.status === 200) {
				setBrandList(response.results);
			}
		}
	};
	useEffect(() => {
		getMyBrandList();
	}, []);

	let lodeMoreData = async () => {
		if (searchQ) {
			if (myBrand) {
				const response = await Brand.getMyBrandListSearch(
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				}
			} else {
				const response = await Brand.getAllBrandListSearch(
					searchQ,
					pageCount
				);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				}
			}
		} else {
			if (myBrand) {
				const response = await Brand.getMyBrandList(pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				}
			} else {
				const response = await Brand.getAllBrandList(pageCount);
				if (response.status === 200) {
					setPageCount((prev) => prev + 1);
					setBrandList([...brandList, ...response.results]);
				}
			}
		}
	};

	let singleBrandSearch = async () => {
		if (myBrand) {
			let response = await Brand.getMyBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		} else {
			let response = await Brand.getAllBrandListSearch(searchQ);
			if (response.status === 200) {
				setBrandList(response.results);
			}
		}
	};

	useEffect(() => {
		if (searchQ === "") {
			if (myBrand) {
				getMyBrandList();
			} else {
				getAllBrandList();
			}
		}
	}, [searchQ]);

	return (
		<IonApp>
			<IonPage>
				<IonContent>
					<BackButtonTitle
						title={ekkLanguage.brandList.pageTitle}
						from='brandList'
					/>
					<section className='pt-14'>
						<BrandList
							ekkLanguage={ekkLanguage}
							brandList={brandList}
							setMyBrand={setMyBrand}
							getAllBrandList={getAllBrandList}
							getMyBrandList={getMyBrandList}
							searchQ={searchQ}
							setSearchQ={setSearchQ}
							singleBrandSearch={singleBrandSearch}
						/>
					</section>
					<div>
						<IonInfiniteScroll
							onIonInfinite={(ev) => {
								lodeMoreData();
								setTimeout(() => ev.target.complete(), 1000);
							}}>
							<IonInfiniteScrollContent
								className='pb-24'
								loadingText={ekkLanguage.orderList.loader}
								loadingSpinner='bubbles'></IonInfiniteScrollContent>
						</IonInfiniteScroll>
					</div>
					<BrandBottomSheet />
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
