import { getLanguage } from "config/language";
import { useState } from "react";
import { useSelector } from "react-redux";
import PhoneNoCheck from "./PhoneNoCheck";
import { Transition, animated } from "react-spring/renderprops";
import { authBox_Block } from "Constants";
import Password from "./Password";

export default function BannerContent() {
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("+88");
  const [country, setCountry] = useState("Bangladesh");
  const [phoneNo, setPhoneNo] = useState("");
  const ekkLanguage = useSelector((state) => state.lan);
  var sSize = window.matchMedia("(max-width: 599px)");
  const [blockIndex, setBlockIndex] = useState(
    authBox_Block.PHONE_NUMBER_BLOCK
  );
  const [password, setPassword] = useState("");
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const startLoading = () => setLoading(true);
  const stopLoading = () => setLoading(false);

  return (
    <div className="sm:pt-[185px] pt-[120px]">
      <div className="flex w-full justify-center items-center lg:my-5 md:my-4 my-10">
        <img
          src="/assets/images/ekkhero_pilot.png"
          alt="logo"
          className="w-[207px] h-[100px]"
        />
      </div>

      <div className="pt-12 relative">
        <Transition
          native
          reset
          unique
          items={blockIndex}
          from={{
            opacity: 0,
            transform: "translate3d(30%,0,0)",
          }}
          enter={{ opacity: 1, transform: "translate3d(0%,0,0)" }}
          leave={{
            opacity: 0,
            transform: "translate3d(-30%,0,0)",
          }}
        >
          {(index) => {
            if (index === authBox_Block.PHONE_NUMBER_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <PhoneNoCheck
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                    country={country}
                    setCountry={setCountry}
                    phoneNo={phoneNo}
                    setPhoneNo={setPhoneNo}
                    sSize={sSize}
                    setBlockIndex={setBlockIndex}
                  />
                </animated.div>
              );
            }
            if (index === authBox_Block.PASSWORD_BLOCK) {
              return (style) => (
                <animated.div className="absolute w-full" style={{ ...style }}>
                  <div>
                    <Password
                      setPassword={setPassword}
                      loading={loading}
                      setIsForgotPassword={setIsForgotPassword}
                      password={password}
                      phoneNo={phoneNo}
                      country={country}
                      countryCode={countryCode}
                      startLoading={startLoading}
                      stopLoading={stopLoading}
                      setBlockIndex={setBlockIndex}
                      sSize={sSize}
                    />
                  </div>
                </animated.div>
              );
            }
          }}
        </Transition>
      </div>
    </div>
  );
}
