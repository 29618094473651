import { setLanguage } from "config/language";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectors } from "redux/container/authSlice";
import BannerArea from "./BannerArea";
import Footer from "./Footer";
import Navigation from "./Navigation";

export default function Landing() {
  let navigate = useNavigate();
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const { loginStatus } = useSelector(selectors.getAuth);

  useEffect(() => {
    function onScroll() {
      // let currentPosition = window.pageYOffset;
      // document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      let currentPosition = document.documentElement.scrollTop; // or use document.documentElement.scrollTop;
      if (currentPosition > 150) {
        setScrolling(true);
      } else {
        setScrolling(false);
      }
      setScrollTop(currentPosition);
    }

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  if (localStorage.getItem("lan") === null) {
    setLanguage();
  }

  useEffect(() => {
    navigate(loginStatus ? "/product-list" : "/");
  }, [loginStatus, navigate]);

  return (
    <section>
      <Navigation scrolling={scrolling ? "isScrolled" : ""} />
      <BannerArea />
      <Footer />
    </section>
  );
}
