export default function Employees() {
	return (
		<svg
			width='20'
			height='16'
			viewBox='0 0 20 16'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<path
				d='M14.9062 7.08057C16.0688 6.91723 16.9638 5.92056 16.9663 4.71306C16.9663 3.52306 16.0988 2.5364 14.9613 2.34973'
				stroke='#130F26'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M16.4399 9.87524C17.5658 10.0436 18.3516 10.4377 18.3516 11.2502C18.3516 11.8094 17.9816 12.1727 17.3833 12.4011'
				stroke='#130F26'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.90529 10.2198C7.22695 10.2198 4.93945 10.6256 4.93945 12.2465C4.93945 13.8665 7.21279 14.284 9.90529 14.284C12.5836 14.284 14.8703 13.8823 14.8703 12.2606C14.8703 10.639 12.5978 10.2198 9.90529 10.2198Z'
				stroke='#130F26'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.90518 7.90656C11.6627 7.90656 13.0877 6.4824 13.0877 4.72406C13.0877 2.96656 11.6627 1.54156 9.90518 1.54156C8.14768 1.54156 6.72268 2.96656 6.72268 4.72406C6.71601 6.47573 8.13018 7.90073 9.88185 7.90656H9.90518Z'
				stroke='#130F26'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M4.90375 7.08057C3.74042 6.91723 2.84625 5.92056 2.84375 4.71306C2.84375 3.52306 3.71125 2.5364 4.84875 2.34973'
				stroke='#130F26'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
			<path
				d='M3.36967 9.87524C2.24384 10.0436 1.45801 10.4377 1.45801 11.2502C1.45801 11.8094 1.82801 12.1727 2.42634 12.4011'
				stroke='#130F26'
				strokeWidth='1.5'
				strokeLinecap='round'
				strokeLinejoin='round'
			/>
		</svg>
	);
}
