import React from "react";

export default function EyeOpen() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<path
				d='M7.99965 10.6656C9.47185 10.6656 10.6653 9.47216 10.6653 7.99995C10.6653 6.52775 9.47185 5.33429 7.99965 5.33429C6.52744 5.33429 5.33398 6.52775 5.33398 7.99995C5.33398 9.47216 6.52744 10.6656 7.99965 10.6656Z'
				fill='white'
			/>
			<path
				d='M15.5112 6.28004C14.4776 4.59666 12.1265 1.77237 7.99998 1.77237C3.87352 1.77237 1.52239 4.59666 0.488772 6.28004C-0.162924 7.33412 -0.162924 8.666 0.488772 9.72011C1.52239 11.4035 3.87352 14.2278 7.99998 14.2278C12.1265 14.2278 14.4776 11.4035 15.5112 9.72011C16.1629 8.666 16.1629 7.33412 15.5112 6.28004ZM7.99998 11.9986C5.79168 11.9986 4.00147 10.2084 4.00147 8.00006C4.00147 5.79175 5.79168 4.00154 7.99998 4.00154C10.2083 4.00154 11.9985 5.79175 11.9985 8.00006C11.9963 10.2075 10.2074 11.9964 7.99998 11.9986Z'
				fill='white'
			/>
		</svg>
	);
}
