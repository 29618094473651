import React from "react";
import { useSelector } from "react-redux";

export default function FooterV2() {
	const ekkLanguage = useSelector((state) => state.lan.lan);
	return (
		<div className='lg:absolute bottom-0 left-0 right-0'>
			<hr className='border border-[#0000001A]' />
			<div className='flex lg:justify-between lg:flex-row flex-col justify-center items-center container lg:py-3 py-2'>
				<div className='flex flex-col'>
					<p className='text-[#808080] font-semibold text-16 lg:text-left text-center'>
						&copy; <span>Enam Pte. Ltd.</span>
					</p>
					<p className='text-[#808080] font-semibold text-16 lg:text-left text-center'>
						Paya Lebar Square #07-54, 60 Paya Lebar Road Singapore 409051
					</p>
				</div>
				<div className='flex flex-col'>
					<p className='text-[#000000] font-bold text-16'>
						{ekkLanguage.landing.downloadApp}
					</p>
					<div className='flex justify-center lg:justify-start mt-1'>
						<a
							className='mr-4'
							// target='_blank'
							href="#!"
							// href='https://play.google.com/store/apps/details?id=ekkbaz.com&hl=en&gl=US'
							rel='noreferrer'>
							<img
								src='assets/images/play-store.png'
								alt='play'
								className='h-5 w-[66px]'
							/>
						</a>
						<a
							className=''
							// target='_blank'
							href="#!"
							// href='https://apps.apple.com/us/app/ekkbaz-flexible-work-earn/id1448679586'
							rel='noreferrer'>
							<img
								src='assets/images/app-store.png'
								alt='app'
								className='h-5 w-[66px]'
							/>
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
