import { IonSegment, IonSegmentButton } from "@ionic/react";
import React, { memo } from "react";

function BrandFilter({
	ekkLanguage,
	setMyBrand,
	getAllBrandList,
	getMyBrandList,
}) {
	return (
		<IonSegment scrollable className='orderBtnWrapper' value='myBrand'>
			<IonSegmentButton
				value='myBrand'
				className=' text-primary no-ripple'
				onClick={() => {
					setMyBrand(true);
					getMyBrandList();
				}}>
				<button className='text-primary font-sans p-0 font-normal shadow-none text-14'>
					{ekkLanguage.brandList.myBrand}
				</button>
			</IonSegmentButton>
		</IonSegment>
	);
}
export default memo(BrandFilter);
