import { IonCol, IonSpinner } from "@ionic/react";
import Filter from "assets/images/icon/svg/Filter";
import React, { useState } from "react";
import Search from "../Common/Search";
import ProductFilter from "./ProductFilter";
import SingleProduct from "./SingleProduct";

export default function ProductList({
	ekkLanguage,
	productList,
	setSelectedItem,
	onOpenModal,
	onOpenUnPublishModal,
	loader,
	setSearchQ,
	searchQ,
	singleProductSearch,
	setFilteredSelections,
	filterHandler,
	selectedFilter,
	tempSearchParams,
	setTempSearchParams,
}) {
	return (
		<div className='mt-[10px]'>
			<Search
				search={searchQ}
				setSearch={setSearchQ}
				ekkLanguage={ekkLanguage}
				placeholder={ekkLanguage.productList.searchYourProduct}
				singleSearch={singleProductSearch}
				filter={true}
				filterHandler={filterHandler}
				selectedFilter={selectedFilter}
				setTempSearchParams={setTempSearchParams}
			/>
			<p className='px-4 pt-2'>
				{" "}
				{tempSearchParams && (
					<span>
						search: <span className='font-bold'> {tempSearchParams}</span>
					</span>
				)}
			</p>
			<div>
				<div className='pt-2 pb-5'>
					<ProductFilter
						ekkLanguage={ekkLanguage}
						setFilteredSelections={setFilteredSelections}
					/>
				</div>
				{loader ? (
					<div className='flex items-center justify-center h-[156px] m-auto'>
						{" "}
						<IonSpinner name='crescent'></IonSpinner>
					</div>
				) : (
					<>
						{productList.length ? (
							productList.map((el, i) => (
								<SingleProduct
									published={true}
									key={i}
									item={el}
									setSelectedItem={setSelectedItem}
									onOpenModal={onOpenModal}
									onOpenUnPublishModal={onOpenUnPublishModal}
								/>
							))
						) : (
							<>
								<IonCol size='2'></IonCol>
								<IonCol size='8'>
									<div
										className='text-center flex items-center justify-center'
										style={{ height: "calc(100vh - 420px)" }}>
										<div>
											<img
												className='m-auto mb-[10px] mt-5'
												src='/assets/images/not-found-file.png'
												alt='emptyCard'
											/>
											<p className='text-16 font-normal text-222222 mb-[35px]'>
												{ekkLanguage.orderList.notFound}
											</p>
										</div>
									</div>
								</IonCol>
							</>
						)}
					</>
				)}
			</div>
		</div>
	);
}
