import { combineReducers } from "redux";
import auth from "redux/container/authSlice";
import user from "redux/container/userSlice";
import productSlice from "redux/container/productSlice";
import { counterSlice } from "redux/container/counter/counterSlice";
import languageSlice from "redux/container/languageSlice";

const appReducer = combineReducers({
	auth,
	user,
	counter: counterSlice,
	lan: languageSlice,
	product: productSlice,
});

const rootReducer = (state, action) => {
	if (action.type === "auth/logout") state = undefined;
	return appReducer(state, action);
};
export default rootReducer;
