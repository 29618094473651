import { IonCol, IonRow } from "@ionic/react";
import { digitChanger, translator } from "helpers/helper";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SingleProductForCard from "./SingleProductForCard";

export default function ProductCard() {
	let offer = useSelector((state) => state.product.productOffer);
	let [totalAmount, setTotalAmount] = useState(0);
	const ekkLanguage = useSelector((state) => state.lan.lan);

	useEffect(() => {
		let total = 0;
		offer?.productItems?.forEach((el) => {
			let eachPrice = el.base_price * el.orderQuantity;
			total += eachPrice;
		});
		setTotalAmount(total);
	}, [offer]);

	return (
		<div className='pb-5'>
			<div className='bg-white rounded-[5px] pb-5'>
				<div className='px-[10px]'>
					<div>
						<IonRow>
							{offer?.productItems?.map((item) => {
								let image;
								if (item?.images[0]) {
									image = item?.images[0];
								} else {
									if (item.type === "variant") {
										image = "/assets/images/varient_default.png";
									} else if (item.type === "pack") {
										image = "/assets/images/pack_default.png";
									} else if (item.type === "offer") {
										image = "/assets/images/offer_default.png";
									}
								}
								return (
									<>
										<SingleProductForCard
											image={image}
											item={item}
											offerList={offer}
										/>
									</>
								);
							})}
							<IonCol>
								<div className='flex pt-3 justify-between'>
									<p className='text-14 text-primary'>
										{ekkLanguage.createOffer.totalProductCount} :{" "}
										{digitChanger(
											Number(offer?.productItems?.length)
										)}
									</p>
									<p className='text-14 text-primary'>
										{ekkLanguage.createOffer.totalAmount} :{" "}
										{
											offer?.productItems[0]?.country
												?.currency_symbol_native
										}
										{digitChanger(Number(totalAmount).toFixed(2))}
									</p>
								</div>
							</IonCol>
						</IonRow>
					</div>
				</div>
			</div>
		</div>
	);
}
