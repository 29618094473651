import React from "react";

export default function DownloadIcon() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='18'
			height='18'
			viewBox='0 0 18 18'
			fill='none'>
			<path
				d='M6.63835 13.275C7.95636 14.593 10.0933 14.593 11.4113 13.275L11.4114 13.275L13.8204 10.866C14.2673 10.4343 14.2797 9.72214 13.848 9.27524C13.4164 8.82833 12.7042 8.81596 12.2573 9.24761C12.2479 9.25664 12.2387 9.26589 12.2296 9.27524L10.1424 11.3617L10.1251 1.125C10.1251 0.503684 9.62143 0 9.00011 0C8.3788 0 7.87511 0.503684 7.87511 1.125L7.89086 11.3452L5.82012 9.2745C5.37322 8.84285 4.66102 8.85523 4.22937 9.30213C3.80831 9.73811 3.80831 10.4293 4.22937 10.8652L6.63835 13.275Z'
				fill='#F94B41'
			/>
			<path
				d='M16.875 10.875C16.2537 10.875 15.75 11.3787 15.75 12V15.4432C15.7496 15.6125 15.6125 15.7496 15.4433 15.75H2.55674C2.3875 15.7496 2.25039 15.6125 2.25 15.4432V12C2.25 11.3787 1.74632 10.875 1.125 10.875C0.503684 10.875 0 11.3787 0 12V15.4432C0.00165234 16.8546 1.14539 17.9984 2.55674 18H15.4432C16.8546 17.9984 17.9983 16.8546 18 15.4433V12C18 11.3787 17.4963 10.875 16.875 10.875Z'
				fill='#F94B41'
			/>
		</svg>
	);
}
