import { IonApp, IonContent, IonPage } from "@ionic/react";
import LeftSidebar from "components/global-components/Navigation/LeftSidebar";
import BackButtonTitle from "components/mobile-components/Common/BackButtonTitle";
import ProductSearch from "components/mobile-components/SelectProduct/ProductSearch";
import ProductList from "components/mobile-components/products/ProductList";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Supplier from "services/Supplier";

export default function MySupplierPage() {
	let { category } = useParams();
	const [brandData, setBrandData] = useState([]);
	const [pageCount, setPageCount] = useState(2);
	const [menuCollapse, setMenuCollapse] = useState(true);
	const menuIconClick = () => {
		setMenuCollapse(!menuCollapse);
	};
	const ekkLanguage = useSelector((state) => state.lan.lan);
	//get suppliers data
	let getMySupplierList = async () => {
		let response = await Supplier.getMySupplierList();

		if (response.status === 200) {
			const supplier = [];
			response.data.forEach((element) => {
				supplier.push(element.related_business);
			});
			setBrandData(supplier);
		} else {
		}
	};
	useEffect(() => {
		getMySupplierList();
	}, []);

	let link = "mysupplier-details";
	let settinges = {
		nameShow: false,
		skuShow: false,
		priceShow: false,
		oldPriceShow: false,
		distShow: true,
		profitShow: false,
		sellPriceShow: false,
		supplierNameShow: true,
		newPriceShow: false,
		defaultImage: "/assets/images/supplier_default.png",
	};
	async function searchNext(event) {
		let timeout = setTimeout(() => {
			// lodeMoreData();
			// event.target.complete();
			clearTimeout(timeout);
		}, 500);
	}
	return (
		<IonApp>
			<IonPage>
				<IonContent
					scrollEvents={true}
					onIonScrollEnd={(ev) => {
						searchNext(ev);
					}}>
					<LeftSidebar
						isMobile={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<BackButtonTitle
						title={ekkLanguage.home.mySuppliersLabel}
						burgerMenu={true}
						menuCollapse={menuCollapse}
						menuIconClick={menuIconClick}
					/>
					<section className='pt-14'>
						<ProductSearch />
						<ProductList
							productData={brandData}
							category={category}
							settinges={settinges}
							link={link}
						/>
					</section>
				</IonContent>
			</IonPage>
		</IonApp>
	);
}
