import Menu from "assets/images/icon/svg/Menu";
import Phone from "assets/images/icon/svg/Phone";
import { getLanguage, setLanguage } from "config/language";
import { digitChanger } from "helpers/helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLan } from "redux/container/languageSlice";
import "./style.scss";
import Select, { components } from "react-select";

export default function Navigation({ blogPost, scrolling, bg }) {
  const dispatch = useDispatch();
  const appLanguage = getLanguage();
  const [promptInstall, setPromptInstall] = useState(null);
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const ekkLanguage = useSelector((state) => state.lan.lan);
  var sSize = window.matchMedia("(max-width: 599px)");

  const options = [
    {
      value: "bn",
      label: ekkLanguage.landing.bangla,
      image:
        "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-bangladesh.png",
    },
    {
      value: "en",
      label: ekkLanguage.landing.english,
      image:
        "https://ekkfilesdbt.blob.core.windows.net/images/ekkflags-united-states-minor-outlying-islands.png",
    },
  ];
  const customStyles = {
    control: (provided) => ({
      ...provided,
      border: sSize.matches ? "1px solid gray" : "none",
      borderRadius: "10px",
      height: sSize.matches ? 20 : 44,
      padding: sSize.matches ? 0 : 5,
    }),
  };
  const selectedOption = options.find((el) => el.value === appLanguage);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      console.log("we are being triggered :D");
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);
    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const languageSubmitHandler = (e) => {
    setLanguage(e.value);
    dispatch(selectLan());
  };

  const onClickPWADownload = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  // if (!supportsPWA) {
  //   return null;
  // }
  //  show select
  const CustomSingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <div
        className={`flex items-center  ${
          sSize.matches ? "w-[125px]" : "w-[113px]"
        }`}
      >
        <img
          src={selectedOption.image}
          className={`sm:h-[20px] sm:w-[30px]  rounded-[2px] ${
            sSize.matches ? "h-[16px] w-7" : "h-5 w-[33px]"
          }`}
          alt="flag"
          style={{ marginRight: sSize.matches ? "5px" : "10px" }}
        />
        <span
          className={`${sSize.matches ? "text-14 font-medium" : "text-16"}`}
        >
          {children}
        </span>
      </div>
    </components.SingleValue>
  );

  //dropdown styles
  const CustomOption = ({ innerProps, label, data }) => (
    <div
      className={`flex items-center   cursor-pointer ${
        sSize.matches ? "py-0.5 pl-2" : "py-1 pl-[10px]"
      }`}
      {...innerProps}
    >
      <img
        src={data?.image}
        className={`sm:h-[20px] sm:w-[30px]  rounded-[2px] ${
          sSize.matches ? "h-[16px] w-7" : "h-5 w-[33px]"
        }`}
        alt="flag"
        style={{ marginRight: sSize.matches ? "5px" : "10px" }}
      />
      <span className={`${sSize.matches ? "text-14 font-medium" : "text-16"} `}>
        {label}
      </span>
    </div>
  );

  return (
    <>
      <div>
        <div
          className={`absolute z-50 w-full ${scrolling} ${bg && "bg-white"}`}
        >
          <div className={`container hidden ${bg ? "mt-0" : "mt-9"} lg:flex`}>
            <div>
              <Link to="/home">
                <img
                  src="/assets/images/ekkhero_pilot.png"
                  alt="logo"
                  className={` mr-[80px] ${
                    scrolling ? "h-[50px] w-[100px]" : "h-[80px] w-[150px]"
                  }`}
                />
              </Link>
            </div>
            <div className="flex items-center justify-end w-full">
              {/* <ul className="flex items-center header-menu-items">
                <li className={`${!blogPost && "active"}  mr-4 xl:mr-10`}>
                  <Link
                    to="/"
                    className={`text-22 ${
                      bg ? "text-black-0000" : "text-white"
                    }`}
                  >
                    {ekkLanguage.landing.retail}
                  </Link>
                </li>
                <li className="">
                  <a
                    href="https://seller.ekkbaz.com/"
                    className={`${!blogPost && "text-white"}  text-22`}
                  >
                    {ekkLanguage.landing.manufacture}
                  </a>
                </li>
              </ul> */}
              <ul className="flex items-center justify-end">
                <li
                  className={`flex items-center xl:pr-2 pr-1 ${
                    appLanguage === "bn" ? "pt-1" : "pt-[1px]"
                  }`}
                >
                  <Phone />

                  {appLanguage === "vn" ? (
                    <a
                      href="tel: 01701898144"
                      className="text-16  text-primary font-bold pl-1"
                    >
                      {appLanguage === "vn" && "Đường dây nóng"}
                    </a>
                  ) : (
                    <a
                      href="tel: 01701898144"
                      className="text-16  text-primary font-bold xl:pl-2 pl-2"
                    >
                      {appLanguage === "bn" && "হটলাইন"}
                      {appLanguage === "en" && "Hot Line"}
                    </a>
                  )}
                </li>
                <li className="flex items-center  xl:pr-8 pr-2">
                  <a
                    href="https://wa.me/+8801701898144"
                    className="text-18  font-bold xl:pl-2 pl-2"
                  >
                    <img
                      className="h-[24px]"
                      src="/assets/images/whatsapp.png"
                      alt=""
                    />
                  </a>
                </li>
                <li className="xl:pr-10 pr-2">
                  {/* <a
                    href="https://play.google.com/store/apps/details?id=com.ekkbaz.hero&hl=bn&gl=US"
                    target="_blank"
                    rel="noreferrer"
                  > */}
                  <button
                    onClick={onClickPWADownload}
                    className={`pointer text-white text-18 font-extrabold px-2 py-1.5 rounded-[10px] pwa-download-btn flex items-center`}
                  >
                    <img
                      src="/assets/images/googlePlay.png"
                      alt=""
                      className="mr-2"
                    />
                    {ekkLanguage.landing.downloadBtn}{" "}
                  </button>
                  {/* </a> */}
                </li>
                <li className="flex items-center">
                  <Select
                    styles={customStyles}
                    options={options}
                    isSearchable={false}
                    defaultValue={selectedOption}
                    onChange={(e) => languageSubmitHandler(e)}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                  />
                </li>
              </ul>
            </div>
          </div>

          <div className={`container ${bg ? "mt-0 my-1" : "mt-3"}`}>
            <div
              className={`${
                scrolling ? "mt-0 py-2 fixed" : "mt-0"
              } justify-between flex lg:hidden`}
            >
              <div>
                <Link to="/">
                  <img
                    src="/assets/images/ekkhero_pilot.png"
                    alt="logo"
                    className=" w-[80px] h-[40px] mr-[80px]"
                  />
                </Link>
              </div>
              <div className="flex items-center">
                <div className="mr-5">
                  <Select
                    styles={customStyles}
                    options={options}
                    isSearchable={false}
                    defaultValue={selectedOption}
                    onChange={(e) => languageSubmitHandler(e)}
                    components={{
                      IndicatorSeparator: () => null,
                      Option: CustomOption,
                      SingleValue: CustomSingleValue,
                    }}
                  />
                </div>
                <Link to="#!" onClick={() => setShowMenu(!showMenu)}>
                  {bg ? (
                    <Menu color={`#266666`} />
                  ) : (
                    <Menu color={`${scrolling ? "#266666" : "#fff"}`} />
                  )}
                </Link>
              </div>
            </div>
            <div
              className={`w-full bg-white z-50 lg:hidden block top-[130px] p-[20px] rounded-[5px] transition-all ${
                showMenu ? "block" : "hidden"
              }`}
            >
              <div>
                <ul className="flex justify-end flex-col-reverse ">
                  <li>
                    {/* <a
                      href="https://play.google.com/store/apps/details?id=com.ekkbaz.hero&hl=bn&gl=US"
                      target="_blank"
                      rel="noreferrer"
                    > */}
                    <button
                      onClick={onClickPWADownload}
                      className={`pointer text-white text-14 sm:text-18 font-extrabold px-5 py-4 rounded-[10px] pwa-download-btn btn-sm mr-3 flex items-center`}
                    >
                      {/* <Download2 color='#fff' /> */}
                      <img
                        src="/assets/images/googlePlay.png"
                        alt="play"
                        className="mr-2 h-4"
                      />
                      <span className="pl-1">
                        {ekkLanguage.landing.downloadBtn}
                      </span>
                    </button>
                    {/* </a> */}
                  </li>
                </ul>
              </div>
              <div className="py-4">
                {/* <ul>
                  <li className="pb-3">
                    <Link to="#" className="text-16 sm:text-24">
                      {ekkLanguage.landing.retail}
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://seller.ekkbaz.com/"
                      className="text-16 sm:text-24"
                    >
                      {ekkLanguage.landing.manufacture}
                    </a>
                  </li>
                </ul> */}
              </div>

              <hr className="mt-3"></hr>
              <div>
                <div className="flex items-center xl:pr-10 pr-4 align-center justify-center pt-3"></div>
                <div className="flex items-center xl:pr-10 pr-4 align-center justify-between pt-3">
                  <div className="flex items-center">
                    <Phone />

                    <a
                      href="tel:+8801701898144"
                      className="text-18  text-primary font-bold xl:pl-2 pl-2"
                    >
                      {appLanguage === "bn" ? "হটলাইন" : "Hot Line"}
                    </a>
                  </div>
                  <a
                    href="https://wa.me/+8801701898144"
                    className="text-18  font-bold xl:pl-2 pl-2"
                  >
                    <img src="/assets/images/whatsapp.png" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}