import React from "react";

export default function EyeClose() {
	return (
		<svg
			xmlns='http://www.w3.org/2000/svg'
			width='16'
			height='16'
			viewBox='0 0 16 16'
			fill='none'>
			<path
				d='M15.5112 6.27171C14.9096 5.28654 14.1507 4.40656 13.2647 3.66667L15.1307 1.80071C15.3864 1.53597 15.379 1.11409 15.1143 0.858405C14.856 0.608966 14.4466 0.608966 14.1883 0.858405L12.1591 2.89031C10.9012 2.14315 9.46303 1.7537 7.99998 1.76407C3.87417 1.76407 1.52304 4.58836 0.488772 6.27174C-0.162924 7.32582 -0.162924 8.6577 0.488772 9.71181C1.09039 10.697 1.84927 11.577 2.73528 12.3168L0.869318 14.1828C0.604572 14.4385 0.597231 14.8604 0.852918 15.1251C1.1086 15.3899 1.53051 15.3972 1.79522 15.1415C1.80078 15.1361 1.80625 15.1307 1.81162 15.1251L3.84553 13.0912C5.1019 13.8383 6.53831 14.2283 7.99998 14.2194C12.1258 14.2194 14.4769 11.3952 15.5112 9.71177C16.1629 8.6577 16.1629 7.32582 15.5112 6.27171ZM4.0015 7.99176C3.99772 5.78711 5.78184 3.99683 7.98649 3.99305C8.82065 3.99162 9.63431 4.25155 10.3132 4.73631L9.34552 5.70395C8.93929 5.45898 8.47437 5.3284 8.00002 5.32609C6.52781 5.32609 5.33435 6.51955 5.33435 7.99176C5.33666 8.46611 5.46724 8.93103 5.71221 9.33726L4.74457 10.3049C4.26137 9.63037 4.00153 8.82148 4.0015 7.99176ZM8.00002 11.9903C7.17029 11.9902 6.3614 11.7304 5.68687 11.2472L6.65451 10.2796C7.06074 10.5245 7.52566 10.6551 8.00002 10.6574C9.47222 10.6574 10.6657 9.46396 10.6657 7.99176C10.6634 7.5174 10.5328 7.05248 10.2878 6.64626L11.2555 5.67862C12.5366 7.47276 12.1208 9.96584 10.3267 11.247C9.64784 11.7318 8.83417 11.9917 8.00002 11.9903Z'
				fill='white'
			/>
		</svg>
	);
}
